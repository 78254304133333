.dn {
  display: none;
}
.df {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.dfc {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
}
.di {
  display: inline;
}
.db {
  display: block;
}
.dib {
  display: inline-block;
}
.ps {
  position: static;
}
.pr {
  position: relative;
}
.pa {
  position: absolute;
}
.pf {
  position: fixed;
}
.pac {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
.f1 {
  -webkit-flex: 1 1 auto;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
}
.f2 {
  -webkit-flex: 2 1 auto;
      -ms-flex: 2 1 auto;
          flex: 2 1 auto;
}
.f0 {
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
}
.jcc {
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.jcsa {
  -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
          justify-content: space-around;
}
.jcsb {
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.acc {
  -webkit-align-content: center;
      -ms-flex-line-pack: center;
          align-content: center;
}
.aic {
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.ass {
  -webkit-align-self: flex-start;
      -ms-flex-item-align: start;
          align-self: flex-start;
}
.ase {
  -webkit-align-self: flex-end;
      -ms-flex-item-align: end;
          align-self: flex-end;
}
.asc {
  -webkit-align-self: center;
      -ms-flex-item-align: center;
          align-self: center;
}
.fw {
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}
.fnw {
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
}
.n {
  font-weight: normal;
  font-style: normal;
}
.b {
  font-weight: bold;
}
.i {
  font-style: italic;
}
.tc {
  text-align: center;
}
.tr {
  text-align: right;
}
.tl {
  text-align: left;
}
.tj {
  text-align: justify;
}
.mt90 {
  margin-top: 1.2rem;
}
.pt90 {
  padding-top: 1.2rem;
}
#lib .header {
  -webkit-flex: none;
      -ms-flex: none;
          flex: none;
  height: 1.17333333rem;
  font-size: 0.48rem;
  color: #fff;
  background: #15a5a5;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.question-stem {
  color: #333;
  background: rgba(255, 255, 255, 0.8);
  font-size: 0.45333333rem;
  padding-top: 0.53333333rem;
  padding-bottom: 0.53333333rem;
  padding-left: 0.53333333rem;
  padding-right: 0.53333333rem;
  border-radius: 0.13333333rem;
  min-height: 3.46666667rem;
}
.question-option {
  position: relative;
  margin-top: 0.4rem;
  height: 1.33333333rem;
}
.question-option-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.question-option-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: #fff;
  font-size: 0.45333333rem;
}
.question-analysis {
  padding-top: 0.93333333rem;
  font-size: 0.45333333rem;
  color: #333;
}
.question-result {
  color: #20a5a4;
  font-size: 0.64rem;
  padding-top: 0.13333333rem;
  padding-bottom: 0.13333333rem;
}
.question-answer {
  padding-bottom: 0.13333333rem;
}
.question-answer > span {
  color: #20a5a4;
  font-size: 0.64rem;
  padding-left: 0.26666667rem;
}
