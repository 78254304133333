.dn {
  display: none;
}
.df {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.dfc {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
}
.di {
  display: inline;
}
.db {
  display: block;
}
.dib {
  display: inline-block;
}
.ps {
  position: static;
}
.pr {
  position: relative;
}
.pa {
  position: absolute;
}
.pf {
  position: fixed;
}
.pac {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
.f1 {
  -webkit-flex: 1 1 auto;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
}
.f2 {
  -webkit-flex: 2 1 auto;
      -ms-flex: 2 1 auto;
          flex: 2 1 auto;
}
.f0 {
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
}
.jcc {
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.jcsa {
  -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
          justify-content: space-around;
}
.jcsb {
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.acc {
  -webkit-align-content: center;
      -ms-flex-line-pack: center;
          align-content: center;
}
.aic {
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.ass {
  -webkit-align-self: flex-start;
      -ms-flex-item-align: start;
          align-self: flex-start;
}
.ase {
  -webkit-align-self: flex-end;
      -ms-flex-item-align: end;
          align-self: flex-end;
}
.asc {
  -webkit-align-self: center;
      -ms-flex-item-align: center;
          align-self: center;
}
.fw {
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}
.fnw {
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
}
.n {
  font-weight: normal;
  font-style: normal;
}
.b {
  font-weight: bold;
}
.i {
  font-style: italic;
}
.tc {
  text-align: center;
}
.tr {
  text-align: right;
}
.tl {
  text-align: left;
}
.tj {
  text-align: justify;
}
.mt90 {
  margin-top: 1.2rem;
}
.pt90 {
  padding-top: 1.2rem;
}
.gamelist {
  width: 8.53333333rem;
  margin: 0 auto;
  height: 100%;
  padding-top: 1.33333333rem;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
}
.gamelist-hx {
  width: 100%;
  height: 3.8rem;
}
.gamelist-item-bg {
  width: 100%;
  height: 100%;
}
.gamelist-content {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.gamelist-content .title {
  text-align: center;
  width: 100%;
  color: #FFFFFF;
  height: 0.77333333rem;
  line-height: 0.77333333rem;
  font-size: 0.32rem;
  font-weight: 600;
}
.gamelist-content .center {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0 auto;
  width: 7.46666667rem;
  height: 2.26666667rem;
  border-bottom: 1px solid #D4D4D4;
  padding-top: 0.33333333rem;
  padding-bottom: 0.33333333rem;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.gamelist-content .people-count {
  width: 1.86666667rem;
  height: 1.53333333rem;
  background: #D8AD83;
  border-radius: 0.2rem;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
          justify-content: space-around;
  padding-top: 0.13333333rem;
  padding-bottom: 0.13333333rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.gamelist-content .people-count .count {
  width: 1.54666667rem;
  height: 0.89333333rem;
  line-height: 0.89333333rem;
  font-size: 0.6rem;
  font-weight: 600;
  color: #FFFFFF;
  text-align: center;
}
.gamelist-content .people-count .txt {
  width: 1.06666667rem;
  height: 0.37333333rem;
  line-height: 0.37333333rem;
  font-size: 0.26666667rem;
  font-weight: 600;
  color: #FFFFFF;
}
.gamelist-content .message {
  -webkit-flex: auto;
      -ms-flex: auto;
          flex: auto;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 1.53333333rem;
  padding-left: 0.33333333rem;
}
.gamelist-content .message span {
  display: inline-block;
}
.gamelist-content .message .tips {
  width: 100%;
  height: 0.56rem;
  line-height: 0.56rem;
  font-size: 0.4rem;
  font-weight: 600;
  color: #333333;
}
.gamelist-content .message .ctrl {
  padding-top: 0.24rem;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.gamelist-content .message .ctrl .state {
  width: 1.06666667rem;
  font-size: 0.34666667rem;
  color: #999999;
}
.gamelist-content .message .ctrl .btn {
  width: 1.46666667rem;
  height: 0.53333333rem;
  line-height: 0.53333333rem;
  background: #15AEAE;
  border-radius: 4px;
  color: white;
  text-align: center;
}
.gamelist-content .time-box {
  width: 7.46666667rem;
  height: 0.72rem;
  line-height: 0.72rem;
  margin: 0 auto;
  color: #999999;
  font-size: 0.32rem;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
