.bgdiv {
  position: relative;
  -webkit-flex: auto;
      -ms-flex: auto;
          flex: auto;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.bgdiv-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.bgdiv-content {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
}
