.tm-picture-editor-icon-select {
  width: 100%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 24px;
  overflow: auto;
  height: 70px;
  margin-top: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  max-width: 450px;
}
.tm-picture-editor-icon-select .icon-item {
  cursor: pointer;
  background: #eeeeee;
  border-radius: 5px;
  padding: 5px;
  margin-right: 10px;
  width: 48px;
  height: 48px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
