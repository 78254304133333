.tm-fileloader {
  min-width: 20px;
  min-height: 20px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  position: absolute;
}
.tm-fileloader input {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
}
