.tm-slider {
  background-color: transparent;
  display: block;
  position: relative;
  height: 8px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.tm-slider-track {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: #03c0ab;
}
.tm-slider-rail {
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -1.5px;
  width: 100%;
  height: 3px;
  background-color: #ddd;
}
.tm-slider-handle {
  position: absolute;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 8px;
  height: 8px;
  top: 0;
  left: -4px;
  border-radius: 50%;
  border: 1px solid #03c0ab;
  background-color: #03c0ab;
}
.tm-slider-loading {
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -2px;
  width: 100%;
  height: 4px;
  background-color: #e5e5e5;
  background-size: 3em 3em;
  background-image: -webkit-linear-gradient(135deg, transparent 0em, transparent 0.8em, #f4f4f4 0.9em, #f4f4f4 2.1em, transparent 2.1em, transparent 2.9em, #f4f4f4 3.1em);
  background-image: -o-linear-gradient(135deg, transparent 0em, transparent 0.8em, #f4f4f4 0.9em, #f4f4f4 2.1em, transparent 2.1em, transparent 2.9em, #f4f4f4 3.1em);
  background-image: linear-gradient(-45deg, transparent 0em, transparent 0.8em, #f4f4f4 0.9em, #f4f4f4 2.1em, transparent 2.1em, transparent 2.9em, #f4f4f4 3.1em);
  -webkit-animation: TMSliderLoading 400ms infinite linear;
          animation: TMSliderLoading 400ms infinite linear;
}
.tm-slider-loading:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: #e5e5e5;
}
@-webkit-keyframes TMSliderLoading {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 3em 0;
  }
}
@keyframes TMSliderLoading {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 3em 0;
  }
}
