.tm-image {
  min-width: 8px;
  min-height: 8px;
  border-radius: inherit;
}
.tm-image-portrait {
  border: 2px solid white;
  border-radius: 50%;
}
.tm-image-svg {
  fill: currentColor;
  background-size: cover;
}
.tm-image-flex {
  width: 100%;
  height: 100%;
}
.tm-image-xxs {
  width: 15px;
  height: 15px;
}
.tm-image-xs {
  width: 18px;
  height: 18px;
}
.tm-image-sm {
  width: 21px;
  height: 21px;
}
.tm-image-md {
  width: 22px;
  height: 22px;
}
.tm-image-lg {
  width: 36px;
  height: 36px;
}
@-webkit-keyframes TMImgFadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes TMImgFadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.tm-image-editor {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5000;
  background: #333;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.tm-image-editor .tm-image-editor-zone {
  position: relative;
  width: 100%;
  height: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding-bottom: 160px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.tm-image-editor .tm-image-editor-zone .tm-image-editor-canvas {
  position: absolute;
  bottom: 178px;
  left: 8px;
  top: 8px;
  right: 8px;
}
.tm-image-editor .tm-image-editor-zone .tm-image-editor-clipper {
  border: 1PX solid white;
  border-radius: 0;
  position: absolute !important;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: transparent;
}
@media (-webkit-min-device-pixel-ratio: 2), (-o-min-device-pixel-ratio: 2/1), (min-resolution: 2dppx) {
  html:not([data-scale]) .tm-image-editor .tm-image-editor-zone .tm-image-editor-clipper {
    position: relative;
    border: none;
  }
  html:not([data-scale]) .tm-image-editor .tm-image-editor-zone .tm-image-editor-clipper::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 200%;
    height: 200%;
    border: 1PX solid white;
    border-radius: 0;
    -webkit-transform-origin: 0 0;
        -ms-transform-origin: 0 0;
            transform-origin: 0 0;
    -webkit-transform: scale(0.5);
        -ms-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    pointer-events: none;
  }
}
.tm-image-editor .tm-image-editor-zone .tm-image-editor-clipper .tm-image-editor-line-h1 {
  border-top: 1PX solid white;
  position: absolute;
  width: 100%;
  height: 0;
  top: 33.33%;
  left: 0;
  background: transparent;
}
@media (-webkit-min-device-pixel-ratio: 2), (-o-min-device-pixel-ratio: 2/1), (min-resolution: 2dppx) {
  html:not([data-scale]) .tm-image-editor .tm-image-editor-zone .tm-image-editor-clipper .tm-image-editor-line-h1 {
    border-top: none;
  }
  html:not([data-scale]) .tm-image-editor .tm-image-editor-zone .tm-image-editor-clipper .tm-image-editor-line-h1::before {
    content: '';
    position: absolute;
    background-color: white;
    display: block;
    z-index: 1;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    width: 100%;
    height: 1PX;
    -webkit-transform-origin: 50% 50%;
        -ms-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scaleY(0.5);
        -ms-transform: scaleY(0.5);
            transform: scaleY(0.5);
  }
}
@media (-webkit-min-device-pixel-ratio: 2) and (-webkit-min-device-pixel-ratio: 3), (-o-min-device-pixel-ratio: 2/1) and (-o-min-device-pixel-ratio: 3/1), (min-resolution: 2dppx) and (min-resolution: 3dppx) {
  html:not([data-scale]) .tm-image-editor .tm-image-editor-zone .tm-image-editor-clipper .tm-image-editor-line-h1::before {
    -webkit-transform: scaleY(0.33);
        -ms-transform: scaleY(0.33);
            transform: scaleY(0.33);
  }
}
.tm-image-editor .tm-image-editor-zone .tm-image-editor-clipper .tm-image-editor-line-h2 {
  border-top: 1PX solid white;
  position: absolute;
  width: 100%;
  height: 0;
  bottom: 33.33%;
  left: 0;
  background: transparent;
}
@media (-webkit-min-device-pixel-ratio: 2), (-o-min-device-pixel-ratio: 2/1), (min-resolution: 2dppx) {
  html:not([data-scale]) .tm-image-editor .tm-image-editor-zone .tm-image-editor-clipper .tm-image-editor-line-h2 {
    border-top: none;
  }
  html:not([data-scale]) .tm-image-editor .tm-image-editor-zone .tm-image-editor-clipper .tm-image-editor-line-h2::before {
    content: '';
    position: absolute;
    background-color: white;
    display: block;
    z-index: 1;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    width: 100%;
    height: 1PX;
    -webkit-transform-origin: 50% 50%;
        -ms-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scaleY(0.5);
        -ms-transform: scaleY(0.5);
            transform: scaleY(0.5);
  }
}
@media (-webkit-min-device-pixel-ratio: 2) and (-webkit-min-device-pixel-ratio: 3), (-o-min-device-pixel-ratio: 2/1) and (-o-min-device-pixel-ratio: 3/1), (min-resolution: 2dppx) and (min-resolution: 3dppx) {
  html:not([data-scale]) .tm-image-editor .tm-image-editor-zone .tm-image-editor-clipper .tm-image-editor-line-h2::before {
    -webkit-transform: scaleY(0.33);
        -ms-transform: scaleY(0.33);
            transform: scaleY(0.33);
  }
}
.tm-image-editor .tm-image-editor-zone .tm-image-editor-clipper .tm-image-editor-line-v1 {
  border-left: 1PX solid white;
  position: absolute;
  width: 0;
  height: 100%;
  left: 33.33%;
  top: 0;
  background: transparent;
}
@media (-webkit-min-device-pixel-ratio: 2), (-o-min-device-pixel-ratio: 2/1), (min-resolution: 2dppx) {
  html:not([data-scale]) .tm-image-editor .tm-image-editor-zone .tm-image-editor-clipper .tm-image-editor-line-v1 {
    border-left: none;
  }
  html:not([data-scale]) .tm-image-editor .tm-image-editor-zone .tm-image-editor-clipper .tm-image-editor-line-v1::before {
    content: '';
    position: absolute;
    background-color: white;
    display: block;
    z-index: 1;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    width: 1PX;
    height: 100%;
    -webkit-transform-origin: 100% 50%;
        -ms-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    -webkit-transform: scaleX(0.5);
        -ms-transform: scaleX(0.5);
            transform: scaleX(0.5);
  }
}
@media (-webkit-min-device-pixel-ratio: 2) and (-webkit-min-device-pixel-ratio: 3), (-o-min-device-pixel-ratio: 2/1) and (-o-min-device-pixel-ratio: 3/1), (min-resolution: 2dppx) and (min-resolution: 3dppx) {
  html:not([data-scale]) .tm-image-editor .tm-image-editor-zone .tm-image-editor-clipper .tm-image-editor-line-v1::before {
    -webkit-transform: scaleX(0.33);
        -ms-transform: scaleX(0.33);
            transform: scaleX(0.33);
  }
}
.tm-image-editor .tm-image-editor-zone .tm-image-editor-clipper .tm-image-editor-line-v2 {
  border-left: 1PX solid white;
  position: absolute;
  width: 0;
  height: 100%;
  right: 33.33%;
  top: 0;
  background: transparent;
}
@media (-webkit-min-device-pixel-ratio: 2), (-o-min-device-pixel-ratio: 2/1), (min-resolution: 2dppx) {
  html:not([data-scale]) .tm-image-editor .tm-image-editor-zone .tm-image-editor-clipper .tm-image-editor-line-v2 {
    border-left: none;
  }
  html:not([data-scale]) .tm-image-editor .tm-image-editor-zone .tm-image-editor-clipper .tm-image-editor-line-v2::before {
    content: '';
    position: absolute;
    background-color: white;
    display: block;
    z-index: 1;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    width: 1PX;
    height: 100%;
    -webkit-transform-origin: 100% 50%;
        -ms-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    -webkit-transform: scaleX(0.5);
        -ms-transform: scaleX(0.5);
            transform: scaleX(0.5);
  }
}
@media (-webkit-min-device-pixel-ratio: 2) and (-webkit-min-device-pixel-ratio: 3), (-o-min-device-pixel-ratio: 2/1) and (-o-min-device-pixel-ratio: 3/1), (min-resolution: 2dppx) and (min-resolution: 3dppx) {
  html:not([data-scale]) .tm-image-editor .tm-image-editor-zone .tm-image-editor-clipper .tm-image-editor-line-v2::before {
    -webkit-transform: scaleX(0.33);
        -ms-transform: scaleX(0.33);
            transform: scaleX(0.33);
  }
}
.tm-image-editor .tm-image-editor-zone .tm-image-editor-clipper .tm-image-editor-handle-tl {
  position: absolute;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: transparent;
  width: 14px;
  height: 14px;
  border-top: 6px solid white;
  border-left: 6px solid white;
  top: -3px;
  left: -3px;
  padding: 4px;
}
.tm-image-editor .tm-image-editor-zone .tm-image-editor-clipper .tm-image-editor-handle-tr {
  position: absolute;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: transparent;
  width: 14px;
  height: 14px;
  border-top: 6px solid white;
  border-right: 6px solid white;
  top: -3px;
  right: -3px;
  padding: 4px;
}
.tm-image-editor .tm-image-editor-zone .tm-image-editor-clipper .tm-image-editor-handle-bl {
  position: absolute;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: transparent;
  width: 14px;
  height: 14px;
  border-bottom: 6px solid white;
  border-left: 6px solid white;
  bottom: -3px;
  left: -3px;
  padding: 4px;
}
.tm-image-editor .tm-image-editor-zone .tm-image-editor-clipper .tm-image-editor-handle-br {
  position: absolute;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: transparent;
  width: 14px;
  height: 14px;
  border-bottom: 6px solid white;
  border-right: 6px solid white;
  bottom: -3px;
  right: -3px;
  padding: 4px;
}
.tm-image-editor .tm-image-editor-zone .tm-image-editor-clipper .tm-image-editor-handle-ct,
.tm-image-editor .tm-image-editor-zone .tm-image-editor-clipper .tm-image-editor-handle-cb {
  position: absolute;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 20px;
  height: 6px;
  border: none;
  background: white;
  left: 50%;
  margin-left: -10px;
  padding: 4px 0;
}
.tm-image-editor .tm-image-editor-zone .tm-image-editor-clipper .tm-image-editor-handle-cr,
.tm-image-editor .tm-image-editor-zone .tm-image-editor-clipper .tm-image-editor-handle-cl {
  position: absolute;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 6px;
  height: 20px;
  border: none;
  background: white;
  top: 50%;
  margin-top: -10px;
  padding: 0 4px;
}
.tm-image-editor .tm-image-editor-zone .tm-image-editor-clipper .tm-image-editor-handle-ct {
  top: -3px;
}
.tm-image-editor .tm-image-editor-zone .tm-image-editor-clipper .tm-image-editor-handle-cb {
  bottom: -3px;
}
.tm-image-editor .tm-image-editor-zone .tm-image-editor-clipper .tm-image-editor-handle-cr {
  right: -3px;
}
.tm-image-editor .tm-image-editor-zone .tm-image-editor-clipper .tm-image-editor-handle-cl {
  left: -3px;
}
.tm-image-editor .tm-image-editor-menu {
  position: absolute;
  width: 100%;
  height: 160px;
  bottom: 0;
  left: 0;
  background: white;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.tm-image-editor .tm-image-editor-menu-top,
.tm-image-editor .tm-image-editor-menu-center,
.tm-image-editor .tm-image-editor-menu-bottom {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  height: 60px;
}
.tm-image-editor .tm-image-editor-menu-top {
  position: relative;
  padding: 0 32px;
  height: 40px;
}
.tm-image-editor .tm-image-editor-menu-top-slider {
  position: relative;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.tm-image-editor .tm-image-editor-menu-top-slider-track {
  width: 100%;
  height: 4px;
  background: #eee;
}
.tm-image-editor .tm-image-editor-menu-top-slider-rail-left {
  position: absolute;
  top: 50%;
  right: 50%;
  margin-top: -2px;
  width: 0;
  height: 4px;
  background: #03c0ab;
}
.tm-image-editor .tm-image-editor-menu-top-slider-rail-right {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -2px;
  width: 0;
  height: 4px;
  background: #03c0ab;
}
.tm-image-editor .tm-image-editor-menu-top-slider-handle {
  position: absolute;
  width: 16px;
  height: 16px;
  background: white;
  border-radius: 50%;
  border: 2px solid #03c0ab;
  top: 50%;
  margin-left: -10px;
  margin-top: -10px;
}
.tm-image-editor .tm-image-editor-menu-top-slider-dot {
  position: absolute;
  width: 8px;
  height: 8px;
  background: #03c0ab;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  margin-left: -4px;
  margin-top: -4px;
}
.tm-image-editor .tm-image-editor-menu-top-slider-notice {
  position: absolute;
  background: white;
  border: 1px solid #c9c9c9;
  top: -20px;
  left: 50%;
  margin-left: -16px;
  width: 30px;
  height: 30px;
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 15px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.tm-image-editor .tm-image-editor-menu-top-slider-notice-text {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
  font-size: 12px;
  line-height: 30px;
  color: #333;
  text-align: center;
}
.tm-image-editor .tm-image-editor-menu-center {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 24px;
  overflow: auto;
}
.tm-image-editor .tm-image-editor-menu-center-item {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  width: 32px;
  height: 32px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.tm-image-editor .tm-image-editor-menu-center-item svg {
  width: 26px;
  height: 26px;
}
.tm-image-editor .tm-image-editor-menu-bottom {
  position: relative;
  width: 100%;
  height: 50px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding-left: 88px;
  padding-right: 88px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
          justify-content: space-around;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.tm-image-editor .tm-image-editor-menu-bottom-cancel,
.tm-image-editor .tm-image-editor-menu-bottom-confirm {
  position: absolute;
  width: 32px;
  height: 32px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.tm-image-editor .tm-image-editor-menu-bottom-cancel svg,
.tm-image-editor .tm-image-editor-menu-bottom-confirm svg {
  width: 26px;
  height: 26px;
}
.tm-image-editor .tm-image-editor-menu-bottom-cancel {
  left: 0;
  padding-left: 24px;
}
.tm-image-editor .tm-image-editor-menu-bottom-confirm {
  right: 0;
  padding-right: 24px;
}
.tm-image-editor .tm-image-editor-menu-bottom-clip,
.tm-image-editor .tm-image-editor-menu-bottom-rotate {
  width: 32px;
  height: 32px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.tm-image-editor .tm-image-editor-menu-bottom-clip svg,
.tm-image-editor .tm-image-editor-menu-bottom-rotate svg {
  width: 26px;
  height: 26px;
}
.tm-icon {
  fill: currentColor;
  background-size: cover;
  width: 22px;
  height: 22px;
}
.tm-icon-xxs {
  width: 15px;
  height: 15px;
}
.tm-icon-xs {
  width: 18px;
  height: 18px;
}
.tm-icon-sm {
  width: 21px;
  height: 21px;
}
.tm-icon-md {
  width: 22px;
  height: 22px;
}
.tm-icon-lg {
  width: 36px;
  height: 36px;
}
.tm-icon-xl {
  width: 48px;
  height: 48px;
}
.tm-icon-xxl {
  width: 56px;
  height: 56px;
}
.tm-icon-xxxl {
  width: 64px;
  height: 64px;
}
.tm-icon-loading {
  -webkit-animation: cirle-anim 1s linear infinite;
          animation: cirle-anim 1s linear infinite;
}
@-webkit-keyframes cirle-anim {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes cirle-anim {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
