.tm-accordion {
  position: relative;
  border-top: 1PX solid #eee;
}
@media (-webkit-min-device-pixel-ratio: 2), (-o-min-device-pixel-ratio: 2/1), (min-resolution: 2dppx) {
  html:not([data-scale]) .tm-accordion {
    border-top: none;
  }
  html:not([data-scale]) .tm-accordion::before {
    content: '';
    position: absolute;
    background-color: #eee;
    display: block;
    z-index: 1;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    width: 100%;
    height: 1PX;
    -webkit-transform-origin: 50% 50%;
        -ms-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scaleY(0.5);
        -ms-transform: scaleY(0.5);
            transform: scaleY(0.5);
  }
}
@media (-webkit-min-device-pixel-ratio: 2) and (-webkit-min-device-pixel-ratio: 3), (-o-min-device-pixel-ratio: 2/1) and (-o-min-device-pixel-ratio: 3/1), (min-resolution: 2dppx) and (min-resolution: 3dppx) {
  html:not([data-scale]) .tm-accordion::before {
    -webkit-transform: scaleY(0.33);
        -ms-transform: scaleY(0.33);
            transform: scaleY(0.33);
  }
}
.tm-accordion-anim-active {
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}
.tm-accordion-item .tm-accordion-header {
  position: relative;
  color: #000;
  font-size: 17px;
  height: 44px;
  line-height: 44px;
  background-color: #fff;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding-left: 15px;
  padding-right: 30px;
  border-bottom: 1PX solid #eee;
  width: auto;
  overflow: hidden;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  white-space: nowrap;
}
@media (-webkit-min-device-pixel-ratio: 2), (-o-min-device-pixel-ratio: 2/1), (min-resolution: 2dppx) {
  html:not([data-scale]) .tm-accordion-item .tm-accordion-header {
    border-bottom: none;
  }
  html:not([data-scale]) .tm-accordion-item .tm-accordion-header::after {
    content: '';
    position: absolute;
    background-color: #eee;
    display: block;
    z-index: 1;
    top: auto;
    right: auto;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1PX;
    -webkit-transform-origin: 50% 100%;
        -ms-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-transform: scaleY(0.5);
        -ms-transform: scaleY(0.5);
            transform: scaleY(0.5);
  }
}
@media (-webkit-min-device-pixel-ratio: 2) and (-webkit-min-device-pixel-ratio: 3), (-o-min-device-pixel-ratio: 2/1) and (-o-min-device-pixel-ratio: 3/1), (min-resolution: 2dppx) and (min-resolution: 3dppx) {
  html:not([data-scale]) .tm-accordion-item .tm-accordion-header::after {
    -webkit-transform: scaleY(0.33);
        -ms-transform: scaleY(0.33);
            transform: scaleY(0.33);
  }
}
.tm-accordion-item .tm-accordion-header i {
  position: absolute;
  display: block;
  top: 15px;
  right: 15px;
  width: 15px;
  height: 15px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2256%22%20height%3D%2256%22%3E%3Cpath%20fill%3D%22%23C9C9C9%22%20d%3D%22M36.187%2028L15.085%206.898a2.283%202.283%200%201%201%203.228-3.229l22.602%2022.602c.475.475.697%201.107.666%201.729a2.277%202.277%200%200%201-.666%201.729L18.313%2052.331a2.283%202.283%200%201%201-3.228-3.229L36.187%2028z%22%2F%3E%3C%2Fsvg%3E");
  background-size: 100% 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
          transform: rotate(90deg);
  -webkit-transition: -webkit-transform 0.2s ease;
  transition: -webkit-transform 0.2s ease;
  -o-transition: transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
}
.tm-accordion-item .tm-accordion-header[aria-expanded~="true"] i {
  -webkit-transform: rotate(270deg);
      -ms-transform: rotate(270deg);
          transform: rotate(270deg);
}
.tm-accordion-item .tm-accordion-content {
  overflow: hidden;
  background: #fff;
}
.tm-accordion-item .tm-accordion-content .tm-accordion-content-box {
  font-size: 15px;
  color: #333;
  position: relative;
  border-bottom: 1PX solid #eee;
}
@media (-webkit-min-device-pixel-ratio: 2), (-o-min-device-pixel-ratio: 2/1), (min-resolution: 2dppx) {
  html:not([data-scale]) .tm-accordion-item .tm-accordion-content .tm-accordion-content-box {
    border-bottom: none;
  }
  html:not([data-scale]) .tm-accordion-item .tm-accordion-content .tm-accordion-content-box::after {
    content: '';
    position: absolute;
    background-color: #eee;
    display: block;
    z-index: 1;
    top: auto;
    right: auto;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1PX;
    -webkit-transform-origin: 50% 100%;
        -ms-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-transform: scaleY(0.5);
        -ms-transform: scaleY(0.5);
            transform: scaleY(0.5);
  }
}
@media (-webkit-min-device-pixel-ratio: 2) and (-webkit-min-device-pixel-ratio: 3), (-o-min-device-pixel-ratio: 2/1) and (-o-min-device-pixel-ratio: 3/1), (min-resolution: 2dppx) and (min-resolution: 3dppx) {
  html:not([data-scale]) .tm-accordion-item .tm-accordion-content .tm-accordion-content-box::after {
    -webkit-transform: scaleY(0.33);
        -ms-transform: scaleY(0.33);
            transform: scaleY(0.33);
  }
}
.tm-accordion-item .tm-accordion-content .tm-accordion-content-box .tm-list-body {
  border-top: 0;
}
.tm-accordion-item .tm-accordion-content .tm-accordion-content-box .tm-list-body:before {
  display: none !important;
}
.tm-accordion-item .tm-accordion-content.tm-accordion-content-inactive {
  display: none;
}
