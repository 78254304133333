.tm-indicator-circle {
  margin: 10px auto;
  width: 40px;
  height: 40px;
  position: relative;
}
.tm-indicator-circle-child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.tm-indicator-circle-child:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background: var(--color, white);
  border-radius: 100%;
  -webkit-animation: TMIndicatorCircleBounce 1.2s infinite ease-in-out both;
          animation: TMIndicatorCircleBounce 1.2s infinite ease-in-out both;
}
.tm-indicator-circle-circle2 {
  -webkit-transform: rotate(30deg);
      -ms-transform: rotate(30deg);
          transform: rotate(30deg);
}
.tm-indicator-circle-circle3 {
  -webkit-transform: rotate(60deg);
      -ms-transform: rotate(60deg);
          transform: rotate(60deg);
}
.tm-indicator-circle-circle4 {
  -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
          transform: rotate(90deg);
}
.tm-indicator-circle-circle5 {
  -webkit-transform: rotate(120deg);
      -ms-transform: rotate(120deg);
          transform: rotate(120deg);
}
.tm-indicator-circle-circle6 {
  -webkit-transform: rotate(150deg);
      -ms-transform: rotate(150deg);
          transform: rotate(150deg);
}
.tm-indicator-circle-circle7 {
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
}
.tm-indicator-circle-circle8 {
  -webkit-transform: rotate(210deg);
      -ms-transform: rotate(210deg);
          transform: rotate(210deg);
}
.tm-indicator-circle-circle9 {
  -webkit-transform: rotate(240deg);
      -ms-transform: rotate(240deg);
          transform: rotate(240deg);
}
.tm-indicator-circle-circle10 {
  -webkit-transform: rotate(270deg);
      -ms-transform: rotate(270deg);
          transform: rotate(270deg);
}
.tm-indicator-circle-circle11 {
  -webkit-transform: rotate(300deg);
      -ms-transform: rotate(300deg);
          transform: rotate(300deg);
}
.tm-indicator-circle-circle12 {
  -webkit-transform: rotate(330deg);
      -ms-transform: rotate(330deg);
          transform: rotate(330deg);
}
.tm-indicator-circle-circle2:before {
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s;
}
.tm-indicator-circlek-circle3:before {
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
}
.tm-indicator-circle-circle4:before {
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
}
.tm-indicator-circle-circle5:before {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}
.tm-indicator-circle-circle6:before {
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s;
}
.tm-indicator-circle-circle7:before {
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
}
.tm-indicator-circle-circle8:before {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}
.tm-indicator-circle-circle9:before {
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
}
.tm-indicator-circle-circle10:before {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.tm-indicator-circle-circle11:before {
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
}
.tm-indicator-circle-circle12:before {
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s;
}
@-webkit-keyframes TMIndicatorCircleBounce {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0.3);
            transform: scale(0.3);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes TMIndicatorCircleBounce {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0.3);
            transform: scale(0.3);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
