.tm-wingblank {
  margin-left: 8px;
  margin-right: 8px;
}
.tm-wingblank-sm {
  margin-left: 5px;
  margin-right: 5px;
}
.tm-wingblank-md {
  margin-left: 8px;
  margin-right: 8px;
}
.tm-wingblank-lg {
  margin-left: 15px;
  margin-right: 15px;
}
