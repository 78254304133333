.lj-tabs {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.lj-tabs-header {
  position: relative;
  display: inline-block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 100%;
  white-space: nowrap;
  min-width: 100%;
}
.lj-tabs-container {
  height: 100%;
}
.lj-tabs-tab {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
  display: inline-block;
  height: 100%;
  -webkit-transition: color 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  -o-transition: color 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  transition: color 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  cursor: pointer;
}
.lj-tabs-ink-bar {
  z-index: 1;
  position: absolute;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transition: left 0.3s linear;
  -o-transition: left 0.3s linear;
  transition: left 0.3s linear;
  bottom: 0;
}
