.tm-video-control {
  display: block;
  width: 100%;
  height: 52px;
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding-left: 16px;
  padding-right: 16px;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 10;
}
.tm-video-control-active {
  display: block;
}
.tm-video-control-deactive {
  display: none;
}
.tm-video-control-enter {
  -webkit-animation: active 0.7s;
          animation: active 0.7s;
}
.tm-video-control-leave {
  -webkit-animation: deactive 0.7s;
          animation: deactive 0.7s;
}
.tm-video-control-panel {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
}
.tm-video-control-panel > div {
  position: relative;
  width: 100%;
  height: 100%;
}
.tm-video-control-download {
  position: absolute;
  right: 0px;
  top: 0px;
  height: 100%;
  width: 40px;
}
.tm-video-control-download.deactive {
  display: none;
}
.tm-video-control-download.deactive.leave {
  -webkit-animation: deactive 0.7s;
          animation: deactive 0.7s;
}
.tm-video-control-download.active {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.tm-video-control-download.active.enter {
  -webkit-animation: active 0.7s;
          animation: active 0.7s;
}
.tm-video-control-download > div {
  margin-right: 2px;
  background: rgba(0, 0, 0, 0.3);
  padding: 2px;
  border-radius: 2px;
}
.tm-video-control-download,
.tm-video-control-download > div {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
@-webkit-keyframes active {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes active {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes deactive {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes deactive {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
