.tm-whitespace-xs {
  height: 3px;
}
.tm-whitespace-sm {
  height: 6px;
}
.tm-whitespace-md {
  height: 9px;
}
.tm-whitespace-lg {
  height: 15px;
}
.tm-whitespace-xl {
  height: 21px;
}
