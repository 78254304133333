.tm-date-picker-modal {
  border-radius: 16px 16px 0 0;
}
.tm-date-picker-modal-item span {
  white-space: nowrap;
  overflow: hidden;
}
.tm-date-picker-container {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
}
.tm-date-picker-container .tm-date-picker-header {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  height: 44px;
  background-color: #f3f3f3;
  border-radius: inherit;
  padding-left: 20px;
  padding-right: 20px;
}
.tm-date-picker-container .tm-date-picker-header-text {
  font-size: 18px;
  color: #8d8d92;
}
.tm-date-picker-container .tm-date-picker-header-left {
  font-size: 15px;
  color: #8d8d92;
}
.tm-date-picker-container .tm-date-picker-header-right {
  font-size: 15px;
  color: #03c0ab;
}
.tm-date-picker-container .tm-date-picker-header-left-active,
.tm-date-picker-container .tm-date-picker-header-right-active {
  opacity: 0.75;
}
.tm-date-picker-container .tm-date-picker-menu {
  width: 100%;
  height: 72px;
  border-bottom: 1px solid #ddd;
  position: relative;
}
.tm-date-picker-container .tm-date-picker-menu-left,
.tm-date-picker-container .tm-date-picker-menu-right {
  position: absolute;
  width: 128px;
  top: 0;
  bottom: 0;
}
.tm-date-picker-container .tm-date-picker-menu-left-text,
.tm-date-picker-container .tm-date-picker-menu-right-text {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 12px 0;
}
.tm-date-picker-container .tm-date-picker-menu-left-text span,
.tm-date-picker-container .tm-date-picker-menu-right-text span {
  font-size: 18px;
  color: #8d8d92;
}
.tm-date-picker-container .tm-date-picker-menu-left-text-active span,
.tm-date-picker-container .tm-date-picker-menu-right-text-active span {
  color: #03c0ab;
}
.tm-date-picker-container .tm-date-picker-menu-left-text-activebar,
.tm-date-picker-container .tm-date-picker-menu-right-text-activebar {
  position: absolute;
  height: 4px;
  border-radius: 2px;
  left: 0;
  right: 0;
  bottom: 0;
  background: #03c0ab;
}
.tm-date-picker-container .tm-date-picker-menu-left {
  left: 40px;
}
.tm-date-picker-container .tm-date-picker-menu-right {
  right: 40px;
}
.tm-date-picker-container .tm-date-picker-menu-touched {
  opacity: 0.75;
}
