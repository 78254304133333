.bar {
  display: inline-block;
  min-width: 2px;
  max-width: 6px;
  min-height: 8px;
  max-height: 64px;
  margin-left: 2px;
  height: 100%;
  -webkit-animation-name: scale;
          animation-name: scale;
  -webkit-animation-duration: 1.2s;
          animation-duration: 1.2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}
.tm-indicator-wave {
  height: 18px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: height 0.1s;
  -o-transition: height 0.1s;
  transition: height 0.1s;
}
.tm-indicator-wave .bar-one {
  display: inline-block;
  min-width: 2px;
  max-width: 6px;
  min-height: 8px;
  max-height: 64px;
  margin-left: 2px;
  height: 100%;
  -webkit-animation-name: scale;
          animation-name: scale;
  -webkit-animation-duration: 1.2s;
          animation-duration: 1.2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  background-color: #2ecc71;
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
}
.tm-indicator-wave .bar-two {
  display: inline-block;
  min-width: 2px;
  max-width: 6px;
  min-height: 8px;
  max-height: 64px;
  margin-left: 2px;
  height: 100%;
  -webkit-animation-name: scale;
          animation-name: scale;
  -webkit-animation-duration: 1.2s;
          animation-duration: 1.2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  background-color: #3498db;
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
}
.tm-indicator-wave .bar-three {
  display: inline-block;
  min-width: 2px;
  max-width: 6px;
  min-height: 8px;
  max-height: 64px;
  margin-left: 2px;
  height: 100%;
  -webkit-animation-name: scale;
          animation-name: scale;
  -webkit-animation-duration: 1.2s;
          animation-duration: 1.2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  background-color: #9b59b6;
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}
.tm-indicator-wave .bar-four {
  display: inline-block;
  min-width: 2px;
  max-width: 6px;
  min-height: 8px;
  max-height: 64px;
  margin-left: 2px;
  height: 100%;
  -webkit-animation-name: scale;
          animation-name: scale;
  -webkit-animation-duration: 1.2s;
          animation-duration: 1.2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  background-color: #e67e22;
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s;
}
.tm-indicator-wave .bar-five {
  display: inline-block;
  min-width: 2px;
  max-width: 6px;
  min-height: 8px;
  max-height: 64px;
  margin-left: 2px;
  height: 100%;
  -webkit-animation-name: scale;
          animation-name: scale;
  -webkit-animation-duration: 1.2s;
          animation-duration: 1.2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  background-color: #c0392b;
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
}
.tm-indicator-wave .bar-six {
  display: inline-block;
  min-width: 2px;
  max-width: 6px;
  min-height: 8px;
  max-height: 64px;
  margin-left: 2px;
  height: 100%;
  -webkit-animation-name: scale;
          animation-name: scale;
  -webkit-animation-duration: 1.2s;
          animation-duration: 1.2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  background-color: #e74c3c;
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}
.tm-indicator-wave .bar-seven {
  display: inline-block;
  min-width: 2px;
  max-width: 6px;
  min-height: 8px;
  max-height: 64px;
  margin-left: 2px;
  height: 100%;
  -webkit-animation-name: scale;
          animation-name: scale;
  -webkit-animation-duration: 1.2s;
          animation-duration: 1.2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  background-color: #e74c8c;
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
}
@-webkit-keyframes scale {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.2);
            transform: scaleY(0.2);
  }
  20%,
  60% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}
@keyframes scale {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.2);
            transform: scaleY(0.2);
  }
  20%,
  60% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}
