*,
*:before,
*:after {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body {
  background-color: #f5f5f9;
  font-size: 14px;
}
*[contenteditable] {
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
   -ms-user-select: auto !important;
       user-select: auto !important;
}
*:focus {
  outline: none;
}
a {
  background: transparent;
  text-decoration: none;
  outline: none;
}
