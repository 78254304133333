.tm-popover {
  position: absolute;
  z-index: 9999;
}
.tm-popover-hidden {
  display: none;
}
.tm-popover-mask {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: transparent;
  height: 100%;
  z-index: 8888;
}
.tm-popover-mask-hidden {
  display: none;
}
.tm-popover-arrow {
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 1PX;
  background-color: #494b4b;
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
  z-index: 0;
  -webkit-box-shadow: 0 0 2px rgba(0, 0, 0, 0.21);
          box-shadow: 0 0 2px rgba(0, 0, 0, 0.21);
}
.tm-popover-placement-top .tm-popover-arrow,
.tm-popover-placement-topLeft .tm-popover-arrow,
.tm-popover-placement-topRight .tm-popover-arrow {
  -webkit-transform: rotate(225deg);
      -ms-transform: rotate(225deg);
          transform: rotate(225deg);
  bottom: -4px;
}
.tm-popover-placement-top .tm-popover-arrow {
  left: 50%;
}
.tm-popover-placement-topLeft .tm-popover-arrow {
  left: 8px;
}
.tm-popover-placement-topRight .tm-popover-arrow {
  right: 8px;
}
.tm-popover-placement-right .tm-popover-arrow,
.tm-popover-placement-rightTop .tm-popover-arrow,
.tm-popover-placement-rightBottom .tm-popover-arrow {
  -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
  left: -4px;
}
.tm-popover-placement-right .tm-popover-arrow {
  top: 50%;
}
.tm-popover-placement-rightTop .tm-popover-arrow {
  top: 8px;
}
.tm-popover-placement-rightBottom .tm-popover-arrow {
  bottom: 8px;
}
.tm-popover-placement-left .tm-popover-arrow,
.tm-popover-placement-leftTop .tm-popover-arrow,
.tm-popover-placement-leftBottom .tm-popover-arrow {
  -webkit-transform: rotate(135deg);
      -ms-transform: rotate(135deg);
          transform: rotate(135deg);
  right: -4px;
}
.tm-popover-placement-left .tm-popover-arrow {
  top: 50%;
}
.tm-popover-placement-leftTop .tm-popover-arrow {
  top: 8px;
}
.tm-popover-placement-leftBottom .tm-popover-arrow {
  bottom: 8px;
}
.tm-popover-placement-bottom .tm-popover-arrow,
.tm-popover-placement-bottomLeft .tm-popover-arrow,
.tm-popover-placement-bottomRight .tm-popover-arrow {
  top: -4px;
}
.tm-popover-placement-bottom .tm-popover-arrow {
  left: 50%;
}
.tm-popover-placement-bottomLeft .tm-popover-arrow {
  left: 8px;
}
.tm-popover-placement-bottomRight .tm-popover-arrow {
  right: 8px;
}
.tm-popover-inner {
  font-size: 15px;
  color: white;
  background-color: #494b4b;
  border-radius: 3px;
  -webkit-box-shadow: 0 0 2px rgba(0, 0, 0, 0.21);
          box-shadow: 0 0 2px rgba(0, 0, 0, 0.21);
  overflow: hidden;
}
.tm-popover-inner-wrapper {
  position: relative;
  background-color: #494b4b;
  border-radius: 3px;
}
.tm-popover .tm-popover-item {
  padding: 0 8px;
}
.tm-popover .tm-popover-item-container {
  position: relative;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  height: 39px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0 8px;
}
.tm-popover .tm-popover-item:not(:first-child) .tm-popover-item-container {
  border-top: 1PX solid #5b5b5d;
}
@media (-webkit-min-device-pixel-ratio: 2), (-o-min-device-pixel-ratio: 2/1), (min-resolution: 2dppx) {
  html:not([data-scale]) .tm-popover .tm-popover-item:not(:first-child) .tm-popover-item-container {
    border-top: none;
  }
  html:not([data-scale]) .tm-popover .tm-popover-item:not(:first-child) .tm-popover-item-container::before {
    content: '';
    position: absolute;
    background-color: #5b5b5d;
    display: block;
    z-index: 1;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    width: 100%;
    height: 1PX;
    -webkit-transform-origin: 50% 50%;
        -ms-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scaleY(0.5);
        -ms-transform: scaleY(0.5);
            transform: scaleY(0.5);
  }
}
@media (-webkit-min-device-pixel-ratio: 2) and (-webkit-min-device-pixel-ratio: 3), (-o-min-device-pixel-ratio: 2/1) and (-o-min-device-pixel-ratio: 3/1), (min-resolution: 2dppx) and (min-resolution: 3dppx) {
  html:not([data-scale]) .tm-popover .tm-popover-item:not(:first-child) .tm-popover-item-container::before {
    -webkit-transform: scaleY(0.33);
        -ms-transform: scaleY(0.33);
            transform: scaleY(0.33);
  }
}
.tm-popover .tm-popover-item.tm-popover-item-active .tm-popover-item-container {
  border-top: 0;
}
.tm-popover .tm-popover-item.tm-popover-item-active .tm-popover-item-container:before {
  display: none !important;
}
.tm-popover .tm-popover-item.tm-popover-item-active + .tm-popover-item .tm-popover-item-container {
  border-top: 0;
}
.tm-popover .tm-popover-item.tm-popover-item-active + .tm-popover-item .tm-popover-item-container:before {
  display: none !important;
}
.tm-popover .tm-popover-item.tm-popover-item-active {
  background-color: #3a3a3c;
}
.tm-popover .tm-popover-item.tm-popover-item-active.tm-popover-item-fix-active-arrow {
  position: relative;
}
.tm-popover .tm-popover-item.tm-popover-item-disabled {
  color: #bbb;
}
.tm-popover .tm-popover-item.tm-popover-item-disabled.tm-popover-item-active {
  background-color: transparent;
}
.tm-popover .tm-popover-item-icon {
  margin-right: 10px;
  width: 18px;
  height: 18px;
}
