.tm-list-header {
  padding: 15px 15px 9px 15px;
  font-size: 14px;
  color: #888;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.tm-list-footer {
  padding: 9px 15px 15px 15px;
  font-size: 14px;
  color: #888;
}
.tm-list-body {
  position: relative;
  background-color: #fff;
  border-top: 1PX solid #eee;
  border-bottom: 1PX solid #eee;
}
@media (-webkit-min-device-pixel-ratio: 2), (-o-min-device-pixel-ratio: 2/1), (min-resolution: 2dppx) {
  html:not([data-scale]) .tm-list-body {
    border-top: none;
  }
  html:not([data-scale]) .tm-list-body::before {
    content: '';
    position: absolute;
    background-color: #eee;
    display: block;
    z-index: 1;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    width: 100%;
    height: 1PX;
    -webkit-transform-origin: 50% 50%;
        -ms-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scaleY(0.5);
        -ms-transform: scaleY(0.5);
            transform: scaleY(0.5);
  }
}
@media (-webkit-min-device-pixel-ratio: 2) and (-webkit-min-device-pixel-ratio: 3), (-o-min-device-pixel-ratio: 2/1) and (-o-min-device-pixel-ratio: 3/1), (min-resolution: 2dppx) and (min-resolution: 3dppx) {
  html:not([data-scale]) .tm-list-body::before {
    -webkit-transform: scaleY(0.33);
        -ms-transform: scaleY(0.33);
            transform: scaleY(0.33);
  }
}
@media (-webkit-min-device-pixel-ratio: 2), (-o-min-device-pixel-ratio: 2/1), (min-resolution: 2dppx) {
  html:not([data-scale]) .tm-list-body {
    border-bottom: none;
  }
  html:not([data-scale]) .tm-list-body::after {
    content: '';
    position: absolute;
    background-color: #eee;
    display: block;
    z-index: 1;
    top: auto;
    right: auto;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1PX;
    -webkit-transform-origin: 50% 100%;
        -ms-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-transform: scaleY(0.5);
        -ms-transform: scaleY(0.5);
            transform: scaleY(0.5);
  }
}
@media (-webkit-min-device-pixel-ratio: 2) and (-webkit-min-device-pixel-ratio: 3), (-o-min-device-pixel-ratio: 2/1) and (-o-min-device-pixel-ratio: 3/1), (min-resolution: 2dppx) and (min-resolution: 3dppx) {
  html:not([data-scale]) .tm-list-body::after {
    -webkit-transform: scaleY(0.33);
        -ms-transform: scaleY(0.33);
            transform: scaleY(0.33);
  }
}
.tm-list-body div:not(:last-child) .tm-list-line {
  border-bottom: 1PX solid #eee;
}
@media (-webkit-min-device-pixel-ratio: 2), (-o-min-device-pixel-ratio: 2/1), (min-resolution: 2dppx) {
  html:not([data-scale]) .tm-list-body div:not(:last-child) .tm-list-line {
    border-bottom: none;
  }
  html:not([data-scale]) .tm-list-body div:not(:last-child) .tm-list-line::after {
    content: '';
    position: absolute;
    background-color: #eee;
    display: block;
    z-index: 1;
    top: auto;
    right: auto;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1PX;
    -webkit-transform-origin: 50% 100%;
        -ms-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-transform: scaleY(0.5);
        -ms-transform: scaleY(0.5);
            transform: scaleY(0.5);
  }
}
@media (-webkit-min-device-pixel-ratio: 2) and (-webkit-min-device-pixel-ratio: 3), (-o-min-device-pixel-ratio: 2/1) and (-o-min-device-pixel-ratio: 3/1), (min-resolution: 2dppx) and (min-resolution: 3dppx) {
  html:not([data-scale]) .tm-list-body div:not(:last-child) .tm-list-line::after {
    -webkit-transform: scaleY(0.33);
        -ms-transform: scaleY(0.33);
            transform: scaleY(0.33);
  }
}
.tm-list-item {
  position: relative;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-left: 15px;
  min-height: 44px;
  background-color: #fff;
  vertical-align: middle;
  overflow: hidden;
  -webkit-transition: background-color 200ms;
  -o-transition: background-color 200ms;
  transition: background-color 200ms;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  /* list左图片显示*/
}
.tm-list-item .tm-list-ripple {
  position: absolute;
  background: transparent;
  display: inline-block;
  overflow: hidden;
  will-change: box-shadow, transform;
  -webkit-transition: background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1), color 0.2s cubic-bezier(0.4, 0, 0.2, 1), -webkit-box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1);
  transition: background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1), color 0.2s cubic-bezier(0.4, 0, 0.2, 1), -webkit-box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1);
  -o-transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1), color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1), color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1), color 0.2s cubic-bezier(0.4, 0, 0.2, 1), -webkit-box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1);
  outline: none;
  cursor: pointer;
  border-radius: 100%;
  -webkit-transform: scale(0);
      -ms-transform: scale(0);
          transform: scale(0);
}
.tm-list-item .tm-list-ripple.tm-list-ripple-animate {
  background-color: hsla(0, 0%, 62%, 0.2);
  -webkit-animation: ripple 1s linear;
          animation: ripple 1s linear;
}
.tm-list-item.tm-list-item-top .tm-list-line {
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.tm-list-item.tm-list-item-top .tm-list-line .tm-list-arrow {
  margin-top: 2px;
}
.tm-list-item.tm-list-item-middle .tm-list-line {
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.tm-list-item.tm-list-item-bottom .tm-list-line {
  -webkit-align-items: flex-end;
      -ms-flex-align: end;
          align-items: flex-end;
}
.tm-list-item.tm-list-item-error .tm-list-line .tm-list-extra {
  color: #f50;
}
.tm-list-item.tm-list-item-error .tm-list-line .tm-list-extra .tm-list-brief {
  color: #f50;
}
.tm-list-item.tm-list-item-active {
  background-color: #ddd;
}
.tm-list-item.tm-list-item-disabled .tm-list-line .tm-list-content,
.tm-list-item.tm-list-item-disabled .tm-list-line .tm-list-extra {
  color: #bbb;
}
.tm-list-item img {
  width: 22px;
  height: 22px;
  vertical-align: middle;
}
.tm-list-item .tm-list-thumb:first-child {
  margin-right: 15px;
}
.tm-list-item .tm-list-thumb:last-child {
  margin-left: 8px;
}
.tm-list-item .tm-list-line {
  position: relative;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex: 1 1;
      -ms-flex: 1 1;
          flex: 1 1;
  -webkit-align-self: stretch;
      -ms-flex-item-align: stretch;
          align-self: stretch;
  padding-right: 15px;
  overflow: hidden;
  /* list左侧主内容*/
  /* list右补充内容*/
  /* 辅助性文字*/
  /* list右侧箭头*/
}
.tm-list-item .tm-list-line .tm-list-content {
  -webkit-flex: 1 1;
      -ms-flex: 1 1;
          flex: 1 1;
  color: #000;
  font-size: 17px;
  line-height: 1.5;
  text-align: left;
  width: auto;
  overflow: hidden;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  white-space: nowrap;
  padding-top: 7px;
  padding-bottom: 7px;
}
.tm-list-item .tm-list-line .tm-list-extra {
  -webkit-flex-basis: 36%;
      -ms-flex-preferred-size: 36%;
          flex-basis: 36%;
  color: #888;
  font-size: 16px;
  line-height: 1.5;
  text-align: right;
  width: auto;
  overflow: hidden;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  white-space: nowrap;
  padding-top: 7px;
  padding-bottom: 7px;
}
.tm-list-item .tm-list-line .tm-list-title {
  width: auto;
  overflow: hidden;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  white-space: nowrap;
}
.tm-list-item .tm-list-line .tm-list-brief {
  color: #888;
  font-size: 15px;
  line-height: 1.5;
  margin-top: 6px;
  width: auto;
  overflow: hidden;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  white-space: nowrap;
}
.tm-list-item .tm-list-line .tm-list-arrow {
  display: block;
  width: 15px;
  height: 15px;
  margin-left: 8px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2256%22%20height%3D%2256%22%3E%3Cpath%20fill%3D%22%23C9C9C9%22%20d%3D%22M36.187%2028L15.085%206.898a2.283%202.283%200%201%201%203.228-3.229l22.602%2022.602c.475.475.697%201.107.666%201.729a2.277%202.277%200%200%201-.666%201.729L18.313%2052.331a2.283%202.283%200%201%201-3.228-3.229L36.187%2028z%22%2F%3E%3C%2Fsvg%3E");
  background-size: 100% 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  visibility: hidden;
}
.tm-list-item .tm-list-line .tm-list-arrow-horizontal {
  visibility: visible;
}
.tm-list-item .tm-list-line .tm-list-arrow-vertical {
  visibility: visible;
  -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
          transform: rotate(90deg);
}
.tm-list-item .tm-list-line .tm-list-arrow-vertical-up {
  visibility: visible;
  -webkit-transform: rotate(270deg);
      -ms-transform: rotate(270deg);
          transform: rotate(270deg);
}
.tm-list-item .tm-list-line-multiple {
  padding: 12.5px 15px 12.5px 0;
}
.tm-list-item .tm-list-line-multiple .tm-list-content {
  padding-top: 0;
  padding-bottom: 0;
}
.tm-list-item .tm-list-line-multiple .tm-list-extra {
  padding-top: 0;
  padding-bottom: 0;
}
.tm-list-item .tm-list-line-wrap .tm-list-content {
  white-space: normal;
}
.tm-list-item .tm-list-line-wrap .tm-list-extra {
  white-space: normal;
}
.tm-list-item select {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  border: 0;
  font-size: 17px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: transparent;
}
@-webkit-keyframes ripple {
  100% {
    opacity: 0;
    -webkit-transform: scale(2.5);
            transform: scale(2.5);
  }
}
@keyframes ripple {
  100% {
    opacity: 0;
    -webkit-transform: scale(2.5);
            transform: scale(2.5);
  }
}
