.tm-badge {
  position: relative;
  display: inline-block;
  line-height: 1;
  vertical-align: middle;
}
.tm-badge-text {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: -6px;
  height: 18px;
  line-height: 18px;
  min-width: 9px;
  border-radius: 12px;
  padding: 0 5px;
  text-align: center;
  font-size: 12px;
  color: #fff;
  background-color: #ff5b05;
  white-space: nowrap;
  -webkit-transform: translateX(-45%);
      -ms-transform: translateX(-45%);
          transform: translateX(-45%);
  -webkit-transform-origin: -10% center;
      -ms-transform-origin: -10% center;
          transform-origin: -10% center;
  z-index: 10;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "\5FAE\8F6F\96C5\9ED1", SimSun, sans-serif;
}
.tm-badge-text a {
  color: #fff;
}
.tm-badge-text p {
  margin: 0;
  padding: 0;
}
.tm-badge-hot .tm-badge-text {
  background-color: #f96268;
}
.tm-badge-dot {
  position: absolute;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
  -webkit-transform-origin: 0 center;
      -ms-transform-origin: 0 center;
          transform-origin: 0 center;
  top: -4px;
  height: 8px;
  width: 8px;
  border-radius: 100%;
  background: #ff5b05;
  z-index: 10;
}
.tm-badge-dot-large {
  height: 16px;
  width: 16px;
}
.tm-badge-not-a-wrapper .tm-badge-text,
.tm-badge-not-a-wrapper .tm-badge-dot {
  top: auto;
  display: block;
  position: relative;
  -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
          transform: translateX(0);
}
.tm-badge-corner {
  width: 80px;
  padding: 8px;
  position: absolute;
  right: -32px;
  top: 8px;
  background-color: #ff5b05;
  color: #fff;
  white-space: nowrap;
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
  text-align: center;
  font-size: 15px;
}
.tm-badge-corner-wrapper {
  overflow: hidden;
}
