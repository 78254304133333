.tm-textedit {
  position: relative;
}
.tm-textedit-editor {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 4px;
  font-size: 0.37333333rem;
  overflow: hidden;
  border: 1px solid #aaa;
  border-radius: 4px;
  word-wrap: break-word;
  word-break: break-all;
  padding-bottom: 36px;
}
.tm-textedit-editor-plaintext {
  padding-bottom: 0;
}
.tm-textedit-editor[contenteditable="true"]:focus:hover {
  cursor: text;
}
.tm-textedit-editor[data-empty="true"]::before {
  position: absolute;
  content: attr(placeholder);
  color: #aaa;
  font-size: 0.37333333rem;
  line-height: 0.37333333rem;
  margin-top: 0.18666667rem;
}
.tm-textedit-editor-editable {
  -webkit-user-select: auto;
  -moz-user-select: auto;
   -ms-user-select: auto;
       user-select: auto;
  -webkit-user-modify: read-write;
}
.tm-textedit-editor-focus-float {
  border: 1px solid #03c0ab;
}
.tm-textedit-editor-focus {
  border: 1px solid #03c0ab;
  border-radius: 4px 4px 0 0;
}
.tm-textedit-editor-pin {
  border-radius: 4px 4px 0 0;
}
.tm-textedit-editor p {
  margin: 4px auto;
  font-size: 0.37333333rem;
}
.tm-textedit-editor b {
  font-weight: bold;
}
.tm-textedit-editor i {
  font-style: italic;
}
.tm-textedit-editor u {
  text-decoration: underline;
}
.tm-textedit-editor a {
  text-decoration: underline;
  font-size: 0.37333333rem;
}
.tm-textedit-editor a:visited {
  text-decoration: underline;
}
.tm-textedit-editor a:hover {
  text-decoration: underline;
}
.tm-textedit-editor a:active {
  text-decoration: underline;
}
.tm-textedit-editor.fixed {
  padding-bottom: 0px;
}
.tm-textedit.fixed {
  padding-bottom: 36px;
}
.tm-textedit.fixed .tm-textedit-operation-menu {
  background: transparent;
}
.tm-textedit-operation {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: height 0.1s;
  -o-transition: height 0.1s;
  transition: height 0.1s;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 0 0 4px 4px;
  border: 1px solid #aaa;
  border-top-width: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.tm-textedit-operation-fixed {
  z-index: 1;
  border: none;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
.tm-textedit-operation-float {
  border: none;
  position: absolute;
  left: 16px;
  right: 16px;
  bottom: 2px;
  background: rgba(230, 230, 230, 0.7);
  border-radius: 4px;
}
.tm-textedit-operation-float-menu-visible {
  border-radius: 0 0 4px 4px;
}
.tm-textedit-operation-zone {
  opacity: 1;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.tm-textedit-operation-zone-icon {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 1px;
  margin: 7px;
}
.tm-textedit-operation-zone-icon-checked {
  background: #03c0ab;
  border-radius: 2px;
}
.tm-textedit-operation-zone-icon-color {
  margin: 0;
  padding: 0;
}
.tm-textedit-operation-zone-color {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: inline-block;
  margin: 4px;
  padding: 2px;
}
.tm-textedit-operation-zone-color-content {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.tm-textedit-operation-zone-line {
  width: 12px;
  height: 0;
  margin-top: 2px;
  border-bottom-width: 2px;
  border-bottom-color: #333;
  border-bottom-style: solid;
}
.tm-textedit-operation-menu {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  -webkit-transition: height 0.2s;
  -o-transition: height 0.2s;
  transition: height 0.2s;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  background: #fefefe;
  border-bottom: 1px solid #eee;
}
.tm-textedit-operation-menu-float {
  background: rgba(200, 200, 200, 0.9);
  border: none;
  border-radius: 4px 4px 0 0;
}
.tm-textedit-operation-menu-item {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  width: 28px;
  height: 28px;
  border-radius: 2px;
  margin: 8px;
}
.tm-textedit-operation-menu-item-size-active {
  border: 1px solid #bbb;
}
.tm-textedit-operation-menu-color {
  border-radius: 50%;
  margin: 4px;
  width: 24px;
  height: 24px;
}
.tm-textedit-operation-menu-link {
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 4px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.tm-textedit-operation-menu-link-brief,
.tm-textedit-operation-menu-link-url {
  width: 100%;
  height: 32px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 4px;
}
.tm-textedit-operation-menu-link-brief span,
.tm-textedit-operation-menu-link-url span {
  height: 24px;
  line-height: 24px;
  font-size: 16px;
  margin-right: 8px;
  color: #333;
}
.tm-textedit-operation-menu-link-brief input,
.tm-textedit-operation-menu-link-url input {
  width: 85%;
  font-size: 16px;
  background: transparent;
  border: none;
  border-bottom: 1px solid #ddd;
}
.tm-textedit-operation-menu-link-brief ::-webkit-input-placeholder,
.tm-textedit-operation-menu-link-url ::-webkit-input-placeholder {
  color: #999 !important;
  font-size: 16px !important;
}
.tm-textedit-operation-menu-link-brief input:focus,
.tm-textedit-operation-menu-link-url input:focus {
  border-bottom: 1px solid #03c0ab;
}
.tm-textedit-operation-menu-link-button {
  width: 80px;
  height: 32px;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
  border-radius: 16px;
  background: -webkit-linear-gradient(left, #03c0ab, #0ddcbd);
  background: -webkit-gradient(linear, left top, right top, from(#03c0ab), to(#0ddcbd));
  background: -o-linear-gradient(left, #03c0ab, #0ddcbd);
  background: linear-gradient(to right, #03c0ab, #0ddcbd);
  color: white;
}
.tm-textedit-operation-menu-link-button-active {
  background: -webkit-linear-gradient(left, #02b09a, #0ccbac);
  background: -webkit-gradient(linear, left top, right top, from(#02b09a), to(#0ccbac));
  background: -o-linear-gradient(left, #02b09a, #0ccbac);
  background: linear-gradient(to right, #02b09a, #0ccbac);
}
.tm-textedit-operation-menu-hidden {
  height: 0;
}
.tm-textedit-operation-scaleout {
  -webkit-animation: TEScaleOut 0.15s cubic-bezier(0.075, 0.82, 0.165, 1);
          animation: TEScaleOut 0.15s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.tm-textedit-operation-scalein {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-animation: TEScaleIn 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
          animation: TEScaleIn 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.tm-textedit-operation-fadeout {
  -webkit-animation: TEFadeOut 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);
          animation: TEFadeOut 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.tm-textedit-operation-fadein {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-animation: TEFadeIn 0.25s cubic-bezier(0.55, 0.055, 0.675, 0.19);
          animation: TEFadeIn 0.25s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.tm-textedit-operation-hidden {
  display: none;
}
@-webkit-keyframes TEScaleOut {
  0% {
    display: -webkit-flex;
    display: flex;
    -webkit-transform: scale3d(0, 1, 0);
            transform: scale3d(0, 1, 0);
    height: 36px;
  }
  100% {
    display: none;
    -webkit-transform: scale3d(0, 0, 0);
            transform: scale3d(0, 0, 0);
    height: 0;
  }
}
@keyframes TEScaleOut {
  0% {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-transform: scale3d(0, 1, 0);
            transform: scale3d(0, 1, 0);
    height: 36px;
  }
  100% {
    display: none;
    -webkit-transform: scale3d(0, 0, 0);
            transform: scale3d(0, 0, 0);
    height: 0;
  }
}
@-webkit-keyframes TEScaleIn {
  0% {
    display: -webkit-flex;
    display: flex;
    -webkit-transform: scale3d(0, 0, 0);
            transform: scale3d(0, 0, 0);
    height: 0;
  }
  100% {
    display: -webkit-flex;
    display: flex;
    -webkit-transform: scale3d(0, 1, 0);
            transform: scale3d(0, 1, 0);
    height: 36px;
  }
}
@keyframes TEScaleIn {
  0% {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-transform: scale3d(0, 0, 0);
            transform: scale3d(0, 0, 0);
    height: 0;
  }
  100% {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-transform: scale3d(0, 1, 0);
            transform: scale3d(0, 1, 0);
    height: 36px;
  }
}
@-webkit-keyframes TEFadeOut {
  0% {
    display: -webkit-flex;
    display: flex;
    opacity: 1;
  }
  100% {
    display: none;
    opacity: 0;
  }
}
@keyframes TEFadeOut {
  0% {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    opacity: 1;
  }
  100% {
    display: none;
    opacity: 0;
  }
}
@-webkit-keyframes TEFadeIn {
  0% {
    display: -webkit-flex;
    display: flex;
    opacity: 0;
  }
  100% {
    display: -webkit-flex;
    display: flex;
    opacity: 1;
  }
}
@keyframes TEFadeIn {
  0% {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    opacity: 0;
  }
  100% {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    opacity: 1;
  }
}
.tm-textedit-link {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 8888;
  background-color: rgba(0, 0, 0, 0.4);
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.tm-textedit-link-content {
  position: relative;
  border-radius: 16px;
  background: #fff;
  width: 80%;
}
.tm-textedit-link-content-header {
  width: 100%;
  height: 44px;
  line-height: 44px;
  text-align: center;
  color: #03c0ab;
  font-size: 18px;
  font-weight: 500;
  border-bottom: 1px solid #ddd;
}
.tm-textedit-link-content-input {
  height: 44px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin-left: 16px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.tm-textedit-link-content-input-border {
  border-bottom: 1px solid #ddd;
}
.tm-textedit-link-content-input-splitter {
  width: 3px;
  height: 20px;
  float: left;
  background: #333;
}
.tm-textedit-link-content-input input {
  width: 100%;
  height: 20px;
  line-height: 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding-left: 4px;
  padding-right: 16px;
  overflow-x: auto;
  overflow-y: hidden;
  font-size: 16px;
  background: transparent;
  border: none;
  color: #333;
}
.tm-textedit-link-content-input input ::-webkit-input-placeholder {
  color: #999 !important;
  font-size: 16px !important;
}
.tm-textedit-link-content-action {
  width: 100%;
  height: 60px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  border-top: 1px solid #ddd;
}
.tm-textedit-link-content-action-button {
  width: 120px;
  height: 32px;
  line-height: 32px;
  color: #fff;
  font-size: 16px;
  text-align: center;
  border-radius: 16px;
  background: -webkit-linear-gradient(left, #03c0ab, #0ddcbd);
  background: -webkit-gradient(linear, left top, right top, from(#03c0ab), to(#0ddcbd));
  background: -o-linear-gradient(left, #03c0ab, #0ddcbd);
  background: linear-gradient(to right, #03c0ab, #0ddcbd);
}
.tm-textedit-link-content-action-button-active {
  background: -webkit-linear-gradient(left, #02b09a, #0ccbac);
  background: -webkit-gradient(linear, left top, right top, from(#02b09a), to(#0ccbac));
  background: -o-linear-gradient(left, #02b09a, #0ccbac);
  background: linear-gradient(to right, #02b09a, #0ccbac);
}
