.tm-navbar {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  height: 44px;
  background-color: #00a790;
  color: #fff;
}
.tm-navbar-left,
.tm-navbar-title,
.tm-navbar-right {
  -webkit-flex: 1 1;
      -ms-flex: 1 1;
          flex: 1 1;
  height: 100%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.tm-navbar-left {
  padding-left: 15px;
  font-size: 16px;
}
.tm-navbar-left-icon {
  margin-right: 5px;
  display: inherit;
}
.tm-navbar-title {
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 18px;
  white-space: nowrap;
}
.tm-navbar-right {
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  font-size: 16px;
  margin-right: 15px;
}
.tm-navbar-light {
  background-color: #fff;
  color: #00a790;
}
.tm-navbar-light .tm-navbar-title {
  color: #000;
}
