.tm-indicator-loading {
  position: relative;
  min-width: 16px;
  min-height: 16px;
  max-width: 64px;
  max-height: 64px;
}
.tm-indicator-loading .tm-indicator-loading-bar11 {
  width: 24%;
  height: 12%;
  background-color: #333;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  opacity: 1;
  -webkit-transform-origin: 0 50%;
      -ms-transform-origin: 0 50%;
          transform-origin: 0 50%;
  -webkit-transform: rotate(330deg) translate(120%, 0);
      -ms-transform: rotate(330deg) translate(120%, 0);
          transform: rotate(330deg) translate(120%, 0);
}
.tm-indicator-loading .tm-indicator-loading-bar11-anim {
  -webkit-animation: loading-thanos 0.6s linear infinite;
          animation: loading-thanos 0.6s linear infinite;
  -webkit-animation-delay: 0.55s;
          animation-delay: 0.55s;
}
.tm-indicator-loading .tm-indicator-loading-bar10 {
  width: 24%;
  height: 12%;
  background-color: #333;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  opacity: 0.91666667;
  -webkit-transform-origin: 0 50%;
      -ms-transform-origin: 0 50%;
          transform-origin: 0 50%;
  -webkit-transform: rotate(300deg) translate(120%, 0);
      -ms-transform: rotate(300deg) translate(120%, 0);
          transform: rotate(300deg) translate(120%, 0);
}
.tm-indicator-loading .tm-indicator-loading-bar10-anim {
  -webkit-animation: loading-thanos 0.6s linear infinite;
          animation: loading-thanos 0.6s linear infinite;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
.tm-indicator-loading .tm-indicator-loading-bar9 {
  width: 24%;
  height: 12%;
  background-color: #333;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  opacity: 0.83333333;
  -webkit-transform-origin: 0 50%;
      -ms-transform-origin: 0 50%;
          transform-origin: 0 50%;
  -webkit-transform: rotate(270deg) translate(120%, 0);
      -ms-transform: rotate(270deg) translate(120%, 0);
          transform: rotate(270deg) translate(120%, 0);
}
.tm-indicator-loading .tm-indicator-loading-bar9-anim {
  -webkit-animation: loading-thanos 0.6s linear infinite;
          animation: loading-thanos 0.6s linear infinite;
  -webkit-animation-delay: 0.45s;
          animation-delay: 0.45s;
}
.tm-indicator-loading .tm-indicator-loading-bar8 {
  width: 24%;
  height: 12%;
  background-color: #333;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  opacity: 0.75;
  -webkit-transform-origin: 0 50%;
      -ms-transform-origin: 0 50%;
          transform-origin: 0 50%;
  -webkit-transform: rotate(240deg) translate(120%, 0);
      -ms-transform: rotate(240deg) translate(120%, 0);
          transform: rotate(240deg) translate(120%, 0);
}
.tm-indicator-loading .tm-indicator-loading-bar8-anim {
  -webkit-animation: loading-thanos 0.6s linear infinite;
          animation: loading-thanos 0.6s linear infinite;
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}
.tm-indicator-loading .tm-indicator-loading-bar7 {
  width: 24%;
  height: 12%;
  background-color: #333;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  opacity: 0.66666667;
  -webkit-transform-origin: 0 50%;
      -ms-transform-origin: 0 50%;
          transform-origin: 0 50%;
  -webkit-transform: rotate(210deg) translate(120%, 0);
      -ms-transform: rotate(210deg) translate(120%, 0);
          transform: rotate(210deg) translate(120%, 0);
}
.tm-indicator-loading .tm-indicator-loading-bar7-anim {
  -webkit-animation: loading-thanos 0.6s linear infinite;
          animation: loading-thanos 0.6s linear infinite;
  -webkit-animation-delay: 0.35s;
          animation-delay: 0.35s;
}
.tm-indicator-loading .tm-indicator-loading-bar6 {
  width: 24%;
  height: 12%;
  background-color: #333;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  opacity: 0.58333333;
  -webkit-transform-origin: 0 50%;
      -ms-transform-origin: 0 50%;
          transform-origin: 0 50%;
  -webkit-transform: rotate(180deg) translate(120%, 0);
      -ms-transform: rotate(180deg) translate(120%, 0);
          transform: rotate(180deg) translate(120%, 0);
}
.tm-indicator-loading .tm-indicator-loading-bar6-anim {
  -webkit-animation: loading-thanos 0.6s linear infinite;
          animation: loading-thanos 0.6s linear infinite;
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}
.tm-indicator-loading .tm-indicator-loading-bar5 {
  width: 24%;
  height: 12%;
  background-color: #333;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  opacity: 0.5;
  -webkit-transform-origin: 0 50%;
      -ms-transform-origin: 0 50%;
          transform-origin: 0 50%;
  -webkit-transform: rotate(150deg) translate(120%, 0);
      -ms-transform: rotate(150deg) translate(120%, 0);
          transform: rotate(150deg) translate(120%, 0);
}
.tm-indicator-loading .tm-indicator-loading-bar5-anim {
  -webkit-animation: loading-thanos 0.6s linear infinite;
          animation: loading-thanos 0.6s linear infinite;
  -webkit-animation-delay: 0.25s;
          animation-delay: 0.25s;
}
.tm-indicator-loading .tm-indicator-loading-bar4 {
  width: 24%;
  height: 12%;
  background-color: #333;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  opacity: 0.41666667;
  -webkit-transform-origin: 0 50%;
      -ms-transform-origin: 0 50%;
          transform-origin: 0 50%;
  -webkit-transform: rotate(120deg) translate(120%, 0);
      -ms-transform: rotate(120deg) translate(120%, 0);
          transform: rotate(120deg) translate(120%, 0);
}
.tm-indicator-loading .tm-indicator-loading-bar4-anim {
  -webkit-animation: loading-thanos 0.6s linear infinite;
          animation: loading-thanos 0.6s linear infinite;
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}
.tm-indicator-loading .tm-indicator-loading-bar3 {
  width: 24%;
  height: 12%;
  background-color: #333;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  opacity: 0.33333333;
  -webkit-transform-origin: 0 50%;
      -ms-transform-origin: 0 50%;
          transform-origin: 0 50%;
  -webkit-transform: rotate(90deg) translate(120%, 0);
      -ms-transform: rotate(90deg) translate(120%, 0);
          transform: rotate(90deg) translate(120%, 0);
}
.tm-indicator-loading .tm-indicator-loading-bar3-anim {
  -webkit-animation: loading-thanos 0.6s linear infinite;
          animation: loading-thanos 0.6s linear infinite;
  -webkit-animation-delay: 0.15s;
          animation-delay: 0.15s;
}
.tm-indicator-loading .tm-indicator-loading-bar2 {
  width: 24%;
  height: 12%;
  background-color: #333;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  opacity: 0.25;
  -webkit-transform-origin: 0 50%;
      -ms-transform-origin: 0 50%;
          transform-origin: 0 50%;
  -webkit-transform: rotate(60deg) translate(120%, 0);
      -ms-transform: rotate(60deg) translate(120%, 0);
          transform: rotate(60deg) translate(120%, 0);
}
.tm-indicator-loading .tm-indicator-loading-bar2-anim {
  -webkit-animation: loading-thanos 0.6s linear infinite;
          animation: loading-thanos 0.6s linear infinite;
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
}
.tm-indicator-loading .tm-indicator-loading-bar1 {
  width: 24%;
  height: 12%;
  background-color: #333;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  opacity: 0.16666667;
  -webkit-transform-origin: 0 50%;
      -ms-transform-origin: 0 50%;
          transform-origin: 0 50%;
  -webkit-transform: rotate(30deg) translate(120%, 0);
      -ms-transform: rotate(30deg) translate(120%, 0);
          transform: rotate(30deg) translate(120%, 0);
}
.tm-indicator-loading .tm-indicator-loading-bar1-anim {
  -webkit-animation: loading-thanos 0.6s linear infinite;
          animation: loading-thanos 0.6s linear infinite;
  -webkit-animation-delay: 0.05s;
          animation-delay: 0.05s;
}
.tm-indicator-loading .tm-indicator-loading-bar0 {
  width: 24%;
  height: 12%;
  background-color: #333;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  opacity: 0.08333333;
  -webkit-transform-origin: 0 50%;
      -ms-transform-origin: 0 50%;
          transform-origin: 0 50%;
  -webkit-transform: rotate(0deg) translate(120%, 0);
      -ms-transform: rotate(0deg) translate(120%, 0);
          transform: rotate(0deg) translate(120%, 0);
}
.tm-indicator-loading .tm-indicator-loading-bar0-anim {
  -webkit-animation: loading-thanos 0.6s linear infinite;
          animation: loading-thanos 0.6s linear infinite;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}
@-webkit-keyframes loading-thanos {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.25;
  }
}
@keyframes loading-thanos {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.25;
  }
}
