.tm-picture-editor {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 8889;
  background: #333;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.tm-picture-editor .tm-picture-editor-header {
  position: absolute;
  width: 100%;
  height: 60px;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  z-index: 1;
}
.tm-picture-editor .tm-picture-editor-header-cancel {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.tm-picture-editor .tm-picture-editor-header-cancel span {
  color: #fff;
  padding: 10px;
  font-size: 16px;
}
.tm-picture-editor .tm-picture-editor-header-center {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-flex: 1 1;
      -ms-flex: 1 1;
          flex: 1 1;
  max-width: 450px;
}
.tm-picture-editor .tm-picture-editor-header-zoom-in,
.tm-picture-editor .tm-picture-editor-header-zoom-out,
.tm-picture-editor .tm-picture-editor-header-zoom-restore,
.tm-picture-editor .tm-picture-editor-header-undo,
.tm-picture-editor .tm-picture-editor-header-rotate,
.tm-picture-editor .tm-picture-editor-header-redo {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.tm-picture-editor .tm-picture-editor-header-zoom-in svg,
.tm-picture-editor .tm-picture-editor-header-zoom-out svg,
.tm-picture-editor .tm-picture-editor-header-zoom-restore svg,
.tm-picture-editor .tm-picture-editor-header-undo svg,
.tm-picture-editor .tm-picture-editor-header-rotate svg,
.tm-picture-editor .tm-picture-editor-header-redo svg {
  width: 26px;
  height: 26px;
}
.tm-picture-editor .tm-picture-editor-header-separate {
  border: 1px solid #646464;
  height: 20px;
}
@media screen and (max-width: 450px) {
  .tm-picture-editor .tm-picture-editor-header-zoom-in,
  .tm-picture-editor .tm-picture-editor-header-zoom-out,
  .tm-picture-editor .tm-picture-editor-header-zoom-restore,
  .tm-picture-editor .tm-picture-editor-header-separate {
    display: none;
  }
}
.tm-picture-editor .tm-picture-editor-header-confirm {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.tm-picture-editor .tm-picture-editor-header-confirm span {
  color: #fff;
  padding: 10px;
  font-size: 16px;
}
.tm-picture-editor .tm-picture-editor-footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  background: white;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.tm-picture-editor .tm-picture-editor-footer-bottom {
  position: relative;
  width: 100%;
  height: 60px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 24px;
  max-width: 450px;
}
.tm-picture-editor .tm-picture-editor-footer-bottom > div svg {
  width: 24px;
  height: 24px;
}
.tm-picture-editor .tm-picture-editor-body {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #383838;
}
.tm-picture-editor .tm-picture-editor-body .fabric-canvas {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #383838;
  overflow: hidden;
}
.tm-picture-editor .tm-picture-editor-body .fabric-canvas .canvas-container {
  margin: 0 auto;
  top: 50%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  overflow: hidden;
}
.tm-picture-editor-alert + div .tm-modal-mask,
.tm-picture-editor-alert + div .tm-modal-wrap {
  z-index: 8890;
}
