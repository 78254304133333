.tm-modal {
  position: relative;
}
.tm-modal:not(.tm-modal-transparent):not(.tm-modal-popup) {
  width: 100%;
  height: 100%;
}
.tm-modal-mask {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  z-index: 8888;
  background-color: rgba(0, 0, 0, 0.4);
}
.tm-modal-mask-hidden {
  display: none;
}
.tm-modal-wrap {
  position: fixed;
  overflow: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  z-index: 8888;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transform: translateZ(1px);
          transform: translateZ(1px);
}
.tm-modal-wrap-popup {
  display: block;
  overflow: hidden;
}
.tm-modal-transparent {
  width: 270px;
}
.tm-modal-transparent .tm-modal-content {
  border-radius: 7px;
  padding-top: 15px;
}
.tm-modal-transparent .tm-modal-content .tm-modal-body {
  padding: 0 15px 15px;
}
.tm-modal-popup {
  position: fixed;
  left: 0;
  width: 100%;
}
.tm-modal-popup-slide-down {
  top: 0;
}
.tm-modal-popup-slide-up {
  bottom: 0;
}
.tm-modal-title {
  margin: 0;
  font-size: 18px;
  line-height: 1;
  color: #000;
  text-align: center;
}
.tm-modal-header {
  padding: 6px 15px 15px;
}
.tm-modal-content {
  position: relative;
  background-color: #fff;
  border: 0;
  background-clip: padding-box;
  text-align: center;
  height: 100%;
  overflow: hidden;
  border-radius: inherit;
}
.tm-modal-close {
  border: 0;
  padding: 0;
  background-color: transparent;
  outline: none;
  position: absolute;
  right: 15px;
  z-index: 999;
  height: 21px;
  width: 21px;
}
.tm-modal-close-x {
  display: inline-block;
  width: 15px;
  height: 15px;
  background-repeat: no-repeat;
  background-size: cover;
}
.tm-modal-body {
  font-size: 15px;
  color: #888;
  height: 100%;
  line-height: 1.5;
  overflow: auto;
}
.tm-modal-button-group-h {
  position: relative;
  border-top: 1PX solid #eee;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
@media (-webkit-min-device-pixel-ratio: 2), (-o-min-device-pixel-ratio: 2/1), (min-resolution: 2dppx) {
  html:not([data-scale]) .tm-modal-button-group-h {
    border-top: none;
  }
  html:not([data-scale]) .tm-modal-button-group-h::before {
    content: '';
    position: absolute;
    background-color: #eee;
    display: block;
    z-index: 1;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    width: 100%;
    height: 1PX;
    -webkit-transform-origin: 50% 50%;
        -ms-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scaleY(0.5);
        -ms-transform: scaleY(0.5);
            transform: scaleY(0.5);
  }
}
@media (-webkit-min-device-pixel-ratio: 2) and (-webkit-min-device-pixel-ratio: 3), (-o-min-device-pixel-ratio: 2/1) and (-o-min-device-pixel-ratio: 3/1), (min-resolution: 2dppx) and (min-resolution: 3dppx) {
  html:not([data-scale]) .tm-modal-button-group-h::before {
    -webkit-transform: scaleY(0.33);
        -ms-transform: scaleY(0.33);
            transform: scaleY(0.33);
  }
}
.tm-modal-button-group-h .tm-modal-button {
  -webkit-touch-callout: none;
  -webkit-flex: 1 1;
      -ms-flex: 1 1;
          flex: 1 1;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #00a790;
  font-size: 18px;
  height: 50px;
  line-height: 50px;
  display: block;
  width: auto;
  overflow: hidden;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  white-space: nowrap;
}
.tm-modal-button-group-h .tm-modal-button:first-child {
  color: #000;
}
.tm-modal-button-group-h .tm-modal-button:last-child {
  position: relative;
  border-left: 1PX solid #eee;
}
@media (-webkit-min-device-pixel-ratio: 2), (-o-min-device-pixel-ratio: 2/1), (min-resolution: 2dppx) {
  html:not([data-scale]) .tm-modal-button-group-h .tm-modal-button:last-child {
    border-left: none;
  }
  html:not([data-scale]) .tm-modal-button-group-h .tm-modal-button:last-child::before {
    content: '';
    position: absolute;
    background-color: #eee;
    display: block;
    z-index: 1;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    width: 1PX;
    height: 100%;
    -webkit-transform-origin: 100% 50%;
        -ms-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    -webkit-transform: scaleX(0.5);
        -ms-transform: scaleX(0.5);
            transform: scaleX(0.5);
  }
}
@media (-webkit-min-device-pixel-ratio: 2) and (-webkit-min-device-pixel-ratio: 3), (-o-min-device-pixel-ratio: 2/1) and (-o-min-device-pixel-ratio: 3/1), (min-resolution: 2dppx) and (min-resolution: 3dppx) {
  html:not([data-scale]) .tm-modal-button-group-h .tm-modal-button:last-child::before {
    -webkit-transform: scaleX(0.33);
        -ms-transform: scaleX(0.33);
            transform: scaleX(0.33);
  }
}
.tm-modal-button-group-v .tm-modal-button {
  -webkit-touch-callout: none;
  position: relative;
  border-top: 1PX solid #eee;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #00a790;
  font-size: 18px;
  height: 50px;
  line-height: 50px;
  display: block;
  width: auto;
  overflow: hidden;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  white-space: nowrap;
}
@media (-webkit-min-device-pixel-ratio: 2), (-o-min-device-pixel-ratio: 2/1), (min-resolution: 2dppx) {
  html:not([data-scale]) .tm-modal-button-group-v .tm-modal-button {
    border-top: none;
  }
  html:not([data-scale]) .tm-modal-button-group-v .tm-modal-button::before {
    content: '';
    position: absolute;
    background-color: #eee;
    display: block;
    z-index: 1;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    width: 100%;
    height: 1PX;
    -webkit-transform-origin: 50% 50%;
        -ms-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scaleY(0.5);
        -ms-transform: scaleY(0.5);
            transform: scaleY(0.5);
  }
}
@media (-webkit-min-device-pixel-ratio: 2) and (-webkit-min-device-pixel-ratio: 3), (-o-min-device-pixel-ratio: 2/1) and (-o-min-device-pixel-ratio: 3/1), (min-resolution: 2dppx) and (min-resolution: 3dppx) {
  html:not([data-scale]) .tm-modal-button-group-v .tm-modal-button::before {
    -webkit-transform: scaleY(0.33);
        -ms-transform: scaleY(0.33);
            transform: scaleY(0.33);
  }
}
.tm-modal-button-active {
  background-color: #ddd;
}
.tm-modal-input-container {
  margin-top: 9px;
  border: 1PX solid #eee;
  border-radius: 3px;
}
@media (-webkit-min-device-pixel-ratio: 2), (-o-min-device-pixel-ratio: 2/1), (min-resolution: 2dppx) {
  html:not([data-scale]) .tm-modal-input-container {
    position: relative;
    border: none;
  }
  html:not([data-scale]) .tm-modal-input-container::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 200%;
    height: 200%;
    border: 1PX solid #eee;
    border-radius: 6px;
    -webkit-transform-origin: 0 0;
        -ms-transform-origin: 0 0;
            transform-origin: 0 0;
    -webkit-transform: scale(0.5);
        -ms-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    pointer-events: none;
  }
}
.tm-modal-input {
  height: 36px;
  line-height: 1;
}
.tm-modal-input:nth-child(2) {
  position: relative;
  border-top: 1PX solid #eee;
}
@media (-webkit-min-device-pixel-ratio: 2), (-o-min-device-pixel-ratio: 2/1), (min-resolution: 2dppx) {
  html:not([data-scale]) .tm-modal-input:nth-child(2) {
    border-top: none;
  }
  html:not([data-scale]) .tm-modal-input:nth-child(2)::before {
    content: '';
    position: absolute;
    background-color: #eee;
    display: block;
    z-index: 1;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    width: 100%;
    height: 1PX;
    -webkit-transform-origin: 50% 50%;
        -ms-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scaleY(0.5);
        -ms-transform: scaleY(0.5);
            transform: scaleY(0.5);
  }
}
@media (-webkit-min-device-pixel-ratio: 2) and (-webkit-min-device-pixel-ratio: 3), (-o-min-device-pixel-ratio: 2/1) and (-o-min-device-pixel-ratio: 3/1), (min-resolution: 2dppx) and (min-resolution: 3dppx) {
  html:not([data-scale]) .tm-modal-input:nth-child(2)::before {
    -webkit-transform: scaleY(0.33);
        -ms-transform: scaleY(0.33);
            transform: scaleY(0.33);
  }
}
.tm-modal-input input {
  position: relative;
  border: 0;
  width: 98%;
  height: 34px;
  top: 1PX;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
}
.tm-modal-input input::-webkit-input-placeholder {
  font-size: 14px;
  color: #ccc;
  padding-left: 8px;
}
.tm-modal-input input::-moz-placeholder {
  font-size: 14px;
  color: #ccc;
  padding-left: 8px;
}
.tm-modal-input input::-ms-input-placeholder {
  font-size: 14px;
  color: #ccc;
  padding-left: 8px;
}
.tm-modal-input input::placeholder {
  font-size: 14px;
  color: #ccc;
  padding-left: 8px;
}
.tm-modal.tm-modal-transparent.tm-modal-android .tm-modal-content {
  border-radius: 0;
}
.tm-modal.tm-modal-transparent.tm-modal-android .tm-modal-content .tm-modal-header {
  padding: 9px 24px 12px;
}
.tm-modal.tm-modal-transparent.tm-modal-android .tm-modal-content .tm-modal-header .tm-modal-title {
  text-align: left;
  font-size: 21px;
  color: #000;
}
.tm-modal.tm-modal-transparent.tm-modal-android .tm-modal-content .tm-modal-body {
  color: #000;
  text-align: left;
  padding: 0 24px 15px;
}
.tm-modal.tm-modal-transparent.tm-modal-android .tm-modal-content .tm-modal-body .tm-modal-input-container {
  border: 0;
  border-bottom: 1PX solid #eee;
}
.tm-modal.tm-modal-transparent.tm-modal-android .tm-modal-content .tm-modal-body .tm-modal-input-container:before {
  display: none !important;
}
@media (-webkit-min-device-pixel-ratio: 2), (-o-min-device-pixel-ratio: 2/1), (min-resolution: 2dppx) {
  html:not([data-scale]) .tm-modal.tm-modal-transparent.tm-modal-android .tm-modal-content .tm-modal-body .tm-modal-input-container {
    border-bottom: none;
  }
  html:not([data-scale]) .tm-modal.tm-modal-transparent.tm-modal-android .tm-modal-content .tm-modal-body .tm-modal-input-container::after {
    content: '';
    position: absolute;
    background-color: #eee;
    display: block;
    z-index: 1;
    top: auto;
    right: auto;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1PX;
    -webkit-transform-origin: 50% 100%;
        -ms-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-transform: scaleY(0.5);
        -ms-transform: scaleY(0.5);
            transform: scaleY(0.5);
  }
}
@media (-webkit-min-device-pixel-ratio: 2) and (-webkit-min-device-pixel-ratio: 3), (-o-min-device-pixel-ratio: 2/1) and (-o-min-device-pixel-ratio: 3/1), (min-resolution: 2dppx) and (min-resolution: 3dppx) {
  html:not([data-scale]) .tm-modal.tm-modal-transparent.tm-modal-android .tm-modal-content .tm-modal-body .tm-modal-input-container::after {
    -webkit-transform: scaleY(0.33);
        -ms-transform: scaleY(0.33);
            transform: scaleY(0.33);
  }
}
.tm-modal.tm-modal-transparent.tm-modal-android .tm-modal-content .tm-modal-body .tm-modal-input-container .tm-modal-input:first-child {
  border-top: 0;
}
.tm-modal.tm-modal-transparent.tm-modal-android .tm-modal-content .tm-modal-body .tm-modal-input-container .tm-modal-input:first-child:before {
  display: none !important;
}
.tm-modal.tm-modal-transparent.tm-modal-android .tm-modal-content .tm-modal-footer {
  padding-bottom: 12px;
}
.tm-modal.tm-modal-transparent.tm-modal-android .tm-modal-content .tm-modal-footer .tm-modal-button-group-h {
  overflow: hidden;
  border-top: 0;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding: 0 12px;
}
.tm-modal.tm-modal-transparent.tm-modal-android .tm-modal-content .tm-modal-footer .tm-modal-button-group-h:before {
  display: none !important;
}
.tm-modal.tm-modal-transparent.tm-modal-android .tm-modal-content .tm-modal-footer .tm-modal-button-group-h .tm-modal-button {
  -webkit-flex: initial;
      -ms-flex: initial;
          flex: initial;
  margin-left: 3px;
  padding: 0 15px;
  height: 48px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.tm-modal.tm-modal-transparent.tm-modal-android .tm-modal-content .tm-modal-footer .tm-modal-button-group-h .tm-modal-button:first-child {
  color: #777;
}
.tm-modal.tm-modal-transparent.tm-modal-android .tm-modal-content .tm-modal-footer .tm-modal-button-group-h .tm-modal-button:last-child {
  border-left: 0;
}
.tm-modal.tm-modal-transparent.tm-modal-android .tm-modal-content .tm-modal-footer .tm-modal-button-group-h .tm-modal-button:last-child:before {
  display: none !important;
}
.tm-modal.tm-modal-transparent.tm-modal-android .tm-modal-content .tm-modal-footer .tm-modal-button-group-v.tm-modal-button-group-normal {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  overflow: hidden;
  padding: 0 12px;
}
.tm-modal.tm-modal-transparent.tm-modal-android .tm-modal-content .tm-modal-footer .tm-modal-button-group-v.tm-modal-button-group-normal .tm-modal-button {
  border-top: 0;
  padding: 0 15px;
  margin-left: 3px;
  height: 48px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.tm-modal.tm-modal-transparent.tm-modal-android .tm-modal-content .tm-modal-footer .tm-modal-button-group-v.tm-modal-button-group-normal .tm-modal-button:before {
  display: none !important;
}
.tm-modal.tm-modal-transparent.tm-modal-android .tm-modal-content .tm-modal-footer .tm-modal-button-group-operation .tm-modal-button {
  text-align: start;
  padding-left: 15px;
}
.tm-modal.tm-modal-operation .tm-modal-content {
  border-radius: 7px;
  height: auto;
  padding-top: 0;
}
.tm-modal.tm-modal-operation .tm-modal-content .tm-modal-body {
  padding: 0!important;
}
.tm-modal.tm-modal-operation .tm-modal-content .tm-modal-button {
  color: #000;
  text-align: left;
  padding-left: 15px;
}
.tm-modal-alert-content,
.tm-modal-propmt-content {
  zoom: 1;
  overflow: hidden;
}
.tm-modal-progress-content {
  margin-top: 4px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.tm-modal-progress-content .tm-modal-progres {
  margin-right: 8px;
  width: 100%;
}
