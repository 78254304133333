.dn {
  display: none;
}
.df {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.dfc {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
}
.di {
  display: inline;
}
.db {
  display: block;
}
.dib {
  display: inline-block;
}
.ps {
  position: static;
}
.pr {
  position: relative;
}
.pa {
  position: absolute;
}
.pf {
  position: fixed;
}
.pac {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
.f1 {
  -webkit-flex: 1 1 auto;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
}
.f2 {
  -webkit-flex: 2 1 auto;
      -ms-flex: 2 1 auto;
          flex: 2 1 auto;
}
.f0 {
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
}
.jcc {
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.jcsa {
  -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
          justify-content: space-around;
}
.jcsb {
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.acc {
  -webkit-align-content: center;
      -ms-flex-line-pack: center;
          align-content: center;
}
.aic {
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.ass {
  -webkit-align-self: flex-start;
      -ms-flex-item-align: start;
          align-self: flex-start;
}
.ase {
  -webkit-align-self: flex-end;
      -ms-flex-item-align: end;
          align-self: flex-end;
}
.asc {
  -webkit-align-self: center;
      -ms-flex-item-align: center;
          align-self: center;
}
.fw {
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}
.fnw {
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
}
.n {
  font-weight: normal;
  font-style: normal;
}
.b {
  font-weight: bold;
}
.i {
  font-style: italic;
}
.tc {
  text-align: center;
}
.tr {
  text-align: right;
}
.tl {
  text-align: left;
}
.tj {
  text-align: justify;
}
.mt90 {
  margin-top: 1.2rem;
}
.pt90 {
  padding-top: 1.2rem;
}
.register-bg {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
}
.register {
  -webkit-flex: auto;
      -ms-flex: auto;
          flex: auto;
  width: 85%;
  margin: 0 auto;
  padding-top: 0.66666667rem;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.register-title {
  font-size: 0.45333333rem;
  padding-top: 0.26666667rem;
  padding-bottom: 0.26666667rem;
  font-weight: 600;
}
.register-input-box {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-size: 0.45333333rem;
  height: 1.2rem;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.register-input-label {
  width: 2.4rem;
  text-align: right;
  font-size: 0.45333333rem;
}
.register-input {
  padding-left: 0.2rem;
  /* 普通输入框 */
  /* 移动端您选择器 */
  /* 性别单选 */
}
.register-input input {
  width: 4.66666667rem;
  height: 0.8rem;
  padding-left: 0.36rem;
  border-radius: 0.16rem;
  border: 1px solid #CCCCCC;
}
.register-input .picker {
  width: 4.66666667rem;
  height: 0.8rem;
  line-height: 0.8rem;
  padding-left: 0.36rem;
  border-radius: 0.16rem;
  background: white;
  border: 1px solid #CCCCCC;
  position: relative;
  color: #635c5c;
}
.register-input .picker .area-name {
  display: inline-block;
  width: 89%;
  overflow: hidden;
  /*超出部分隐藏*/
  white-space: nowrap;
  /*不换行*/
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  /*超出部分文字以...显示*/
}
.register-input .picker .sign {
  width: 0.53333333rem;
  height: 0.34666667rem;
  position: absolute;
  top: 0.24rem;
  right: 0.26666667rem;
  color: #CCCCCC;
}
.register-input .sex {
  width: 5.16rem;
  height: 0.8rem;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.register-input .sex .sex-opt {
  width: 2.4rem;
  height: 0.8rem;
  line-height: 0.8rem;
  border-radius: 0.16rem;
  text-align: center;
}
.register-input .sex .normal {
  background: #FFFFFF;
  color: black;
}
.register-input .sex .active {
  background: #E7F1FF;
  color: #1677FF;
  position: relative;
}
.register-input .sex .active .chose {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 0.66666667rem;
  height: 0.4rem;
}
.register-tips {
  color: #FF3B30;
  margin-top: 0.33333333rem;
  width: 8.58666667rem;
  height: 1.17333333rem;
  line-height: 0.53333333rem;
  font-size: 0.37333333rem;
}
.register-space {
  -webkit-flex: auto;
      -ms-flex: auto;
          flex: auto;
  width: 100%;
}
.sponsor {
  width: 100%;
  text-align: center;
  font-size: 0.37333333rem;
  -webkit-flex: auto;
      -ms-flex: auto;
          flex: auto;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding-bottom: 0.2rem;
}
.must-be {
  color: red;
}
.btn-box {
  margin-top: 0.4rem;
  text-align: center;
}
/* antd-mobile 样式重写 */
.am-input-label {
  text-align: right !important;
}
