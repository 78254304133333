.tm-picture-editor-colors {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  overflow: auto;
}
.tm-picture-editor-colors .color-item {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  width: 32px;
  height: 32px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border: 2px solid red;
  padding: 2px;
  border-radius: 50%;
  cursor: pointer;
}
.tm-picture-editor-colors .color-item span {
  display: inline-block;
  width: 100%;
  height: 100%;
  background-color: red;
  border-radius: 50%;
}
