.tm-video {
  display: block;
  position: relative;
  background-color: black;
  min-width: 64px;
}
.tm-video-fullscreen {
  position: fixed;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  z-index: 9999;
  background-color: black;
}
@media all and (orientation: portrait) {
  .tm-video-landscape-rotate {
    -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
            transform: rotate(90deg);
  }
}
@media all and (orientation: landscape) {
  .tm-video-portrait-rotate {
    -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
            transform: rotate(90deg);
  }
}
.tm-video-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
}
.tm-video-poster {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  border-radius: inherit;
}
.tm-video-transcode {
  position: absolute;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
}
.tm-video-transcode-text {
  font-size: 15px;
  color: white;
  height: 20px;
  line-height: 20px;
  text-align: center;
}
.tm-video-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  border-radius: inherit;
}
.tm-video-layer-preloading {
  width: 1px;
  height: 1px;
}
.tm-video-preload {
  position: fixed;
  left: 0;
  bottom: 0;
  visibility: hidden;
}
.tm-video-control-progress-bar {
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 50%;
  margin-left: 40px;
  margin-right: 32px;
  opacity: 1;
}
.tm-video-control-time-current {
  position: relative;
  font-size: 10px;
  line-height: 100%;
  color: #c9c9c9;
  margin-top: 6px;
  float: left;
  opacity: 1;
}
.tm-video-control-time-total {
  position: relative;
  font-size: 10px;
  line-height: 100%;
  color: #c9c9c9;
  margin-top: 6px;
  float: right;
  opacity: 1;
}
