.tm-progress-outer {
  background-color: #eee;
  display: block;
}
.tm-progress-fixed-outer {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2000;
}
.tm-progress-hide-outer {
  background-color: transparent;
}
.tm-progress-bar {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border: 2px solid #00a790;
  -webkit-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  background: #00a790;
}
