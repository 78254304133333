.tm-checkbox {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 21px;
  height: 21px;
}
.tm-checkbox-image {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.tm-checkbox-inner {
  position: absolute;
  right: 0;
  width: 21px;
  height: 21px;
  border: 1px solid #ccc;
  border-radius: 50%;
  -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.tm-checkbox-inner:after {
  position: absolute;
  display: none;
  top: 1.5px;
  right: 6px;
  z-index: 999;
  width: 5px;
  height: 11px;
  border-style: solid;
  border-width: 0 1px 1px 0;
  content: '\0020';
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
}
.tm-checkbox-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  border: 0 none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
.tm-checkbox.tm-checkbox-checked .tm-checkbox-inner {
  border-color: #00a790;
  background: #00a790;
}
.tm-checkbox.tm-checkbox-checked .tm-checkbox-inner:after {
  display: block;
  border-color: #fff;
}
.tm-checkbox.tm-checkbox-disabled {
  opacity: 0.3;
}
.tm-checkbox.tm-checkbox-disabled.tm-checkbox-checked .tm-checkbox-inner {
  border-color: #888;
  background: none;
}
.tm-checkbox.tm-checkbox-disabled.tm-checkbox-checked .tm-checkbox-inner:after {
  border-color: #888;
}
