.tm-recorder-timeline {
  position: relative;
}
.tm-recorder-timeline canvas {
  height: 100%;
}
.tm-recorder-timeline-indicator {
  position: absolute;
  left: 0;
  width: 0;
  border-right: 1.5px solid #39a8e8;
}
.tm-recorder-timeline-content {
  position: relative;
}
.tm-recorder-modal {
  border-radius: 24px 24px 0 0;
}
.tm-recorder-advance {
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 8px 0;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  overflow: hidden;
}
.tm-recorder-advance-header {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  height: 50px;
  padding: 0 16px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.tm-recorder-advance-header-cancel {
  font-size: 16px;
  color: #8d8d92;
}
.tm-recorder-advance-header-cancel-active {
  color: rgba(141, 141, 146, 0.6);
}
.tm-recorder-advance-header-done {
  font-size: 16px;
  color: #03c1ac;
}
.tm-recorder-advance-header-done-disable {
  color: #c9c9c9;
}
.tm-recorder-advance-header-done-active {
  color: rgba(3, 193, 172, 0.6);
}
.tm-recorder-advance-header-caption {
  font-size: 18px;
  color: #333;
}
.tm-recorder-advance-timeline {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  height: 100px;
  margin: 0;
  padding: 0;
  border: none;
}
.tm-recorder-advance-time {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  color: #8d8d92;
  text-align: center;
}
.tm-recorder-advance-control {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  height: 120px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
          justify-content: space-around;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
}
.tm-recorder-advance-control-knob {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 4px solid #daf6f6;
  background: -webkit-linear-gradient(left, #03c0ab, #0ddcbd);
  background: -webkit-gradient(linear, left top, right top, from(#03c0ab), to(#0ddcbd));
  background: -o-linear-gradient(left, #03c0ab, #0ddcbd);
  background: linear-gradient(to right, #03c0ab, #0ddcbd);
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.tm-recorder-advance-control-knob-active {
  background: -webkit-linear-gradient(left, rgba(3, 192, 171, 0.9), rgba(13, 220, 189, 0.9));
  background: -webkit-gradient(linear, left top, right top, from(rgba(3, 192, 171, 0.9)), to(rgba(13, 220, 189, 0.9)));
  background: -o-linear-gradient(left, rgba(3, 192, 171, 0.9), rgba(13, 220, 189, 0.9));
  background: linear-gradient(to right, rgba(3, 192, 171, 0.9), rgba(13, 220, 189, 0.9));
}
.tm-recorder-advance-control-knob-pause {
  width: 28px;
  height: 28px;
  background: white;
  border-radius: 4px;
}
.tm-recorder-advance-control-knob svg {
  width: 36px;
  height: 36px;
}
.tm-recorder-advance-control-encoder {
  width: 96%;
  height: 3px;
  position: absolute;
  bottom: 4px;
  left: 2%;
}
.tm-recorder-advance-control-encoder-bar {
  border: 2px solid #03c0ab;
  background: #03c0ab;
}
