.tm-gallery {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 8888;
  background-color: black;
}
@-webkit-keyframes turn {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
  }
}
@keyframes turn {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
  }
}
.tm-gallery-content {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.tm-gallery-content .tm-gallery-imageview {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
  overflow: hidden;
}
.tm-gallery-content .tm-gallery-imageview-image {
  position: absolute;
}
.tm-gallery-content .tm-gallery-imageview-loading {
  width: 64px;
  height: 64px;
  -webkit-animation: turn 1s linear infinite;
          animation: turn 1s linear infinite;
}
.tm-gallery-edit,
.tm-gallery-clear {
  position: absolute;
  top: 16px;
  left: 16px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.3);
}
.tm-gallery-edit span,
.tm-gallery-clear span {
  font-size: 16px;
  color: white;
  padding: 6px 10px;
  display: inline-block;
}
.tm-gallery-clear {
  top: unset;
  bottom: 16px;
  z-index: 1;
}
.tm-gallery-rotate {
  position: absolute;
  top: 16px;
  right: 16px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.3);
}
.tm-gallery-rotate svg {
  margin: 8px;
}
.tm-gallery-download {
  position: absolute;
  bottom: 16px;
  right: 16px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.3);
}
.tm-gallery-download svg {
  margin: 8px;
}
.tm-gallery-resolution {
  position: absolute;
  right: 128px;
  left: 128px;
  bottom: 40px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.tm-gallery-resolution-text {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 4px 8px;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.3);
}
.tm-gallery-resolution-text span {
  font-size: 16px;
  color: white;
}
.tm-gallery-indicator {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 16px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.tm-gallery-indicator-item {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #888;
}
.tm-gallery-indicator-item-active {
  background-color: #f3f3f3;
}
