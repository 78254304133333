.tm-hotspot {
  position: relative;
}
.tm-hotspot-bg {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.tm-hotspot-spot {
  position: absolute;
}
.tm-hotspot-spot-selected {
  border: 1px solid #03c0ab;
}
.tm-hotspot-spot-radius {
  border-radius: 50%;
}
.tm-hotspot-spot-image {
  position: relative;
  -o-object-fit: contain;
     object-fit: contain;
}
.tm-hotspot-spot-image-radius {
  border-radius: 50%;
}
