.tm-button {
  display: block;
  outline: 0 none;
  -webkit-appearance: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0;
  margin: 0;
  text-align: center;
  font-size: 18px;
  height: 47px;
  line-height: 47px;
  overflow: hidden;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  word-break: break-word;
  white-space: nowrap;
  color: #000;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #eee;
}
.tm-button-borderfix:before {
  -webkit-transform: scale(0.49) !important;
      -ms-transform: scale(0.49) !important;
          transform: scale(0.49) !important;
}
.tm-button.tm-button-active {
  background-color: #ddd;
}
.tm-button.tm-button-disabled {
  color: rgba(0, 0, 0, 0.3);
  opacity: 0.6;
}
.tm-button-primary {
  color: #fff;
  background-color: #00a790;
  border-radius: 5px;
  border: 1px solid #00a790;
}
.tm-button-primary.tm-button-active {
  color: rgba(255, 255, 255, 0.3);
  background-color: #0e80d2;
}
.tm-button-primary.tm-button-disabled {
  color: rgba(255, 255, 255, 0.6);
  opacity: 0.4;
}
.tm-button-ghost {
  color: #00a790;
  background-color: transparent;
  border-radius: 5px;
  border: 1px solid #00a790;
}
.tm-button-ghost.tm-button-active {
  color: rgba(0, 167, 144, 0.6);
  background-color: transparent;
  border-radius: 5px;
  border: 1px solid rgba(0, 167, 144, 0.6);
}
.tm-button-ghost.tm-button-disabled {
  color: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  opacity: 1;
}
.tm-button-warning {
  color: #fff;
  background-color: #e94f4f;
}
.tm-button-warning.tm-button-active {
  color: rgba(255, 255, 255, 0.3);
  background-color: #d24747;
}
.tm-button-warning.tm-button-disabled {
  color: rgba(255, 255, 255, 0.6);
  opacity: 0.4;
}
.tm-button-inline {
  display: inline-block;
  padding: 0 15px;
}
.tm-button-small {
  font-size: 13px;
  height: 30px;
  line-height: 30px;
  padding: 0 15px;
}
.tm-button-icon {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.tm-button > .tm-button-icon {
  margin-right: 0.5em;
}
