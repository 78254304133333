.g-scroll {
  -webkit-flex: 1 1 auto;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  min-height: auto;
}
.g-scroll.vertical {
  height: 0;
}
.g-scroll .tm-scrollview-vertical {
  min-height: auto;
}
