.tm-audio {
  background-color: transparent;
  height: 72px;
}
.tm-audio-advance {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  height: 72px;
  padding-left: 16px;
  padding-right: 16px;
}
.tm-audio-simple {
  min-width: 24px;
  max-width: 96px;
  min-height: 24px;
  max-height: 96px;
}
.tm-audio-backend {
  width: 1px;
  height: 1px;
  visibility: visible;
  opacity: 0;
}
.tm-audio-control {
  position: relative;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: 100%;
  border-radius: inherit;
}
.tm-audio-control-detail {
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin-left: 12px;
}
.tm-audio-control-text {
  width: 100%;
  height: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.tm-audio-control-filename {
  text-align: left;
  font-size: 14px;
  color: #333;
}
.tm-audio-control-current {
  width: 50%;
  text-align: left;
  font-size: 12px;
  color: #c9c9c9;
}
.tm-audio-control-duration {
  width: 50%;
  text-align: right;
  font-size: 12px;
  color: #c9c9c9;
}
.tm-audio-control-toggle {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 44px;
  height: 44px;
  float: left;
}
.tm-audio-control-toggle-play {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20version%3D%221.1%22%20id%3D%22play%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20width%3D%2280px%22%20height%3D%2280px%22%20viewBox%3D%220%200%2080%2080%22%20style%3D%22enable-background%3Anew%200%200%2080%2080%3B%22%20xml%3Aspace%3D%22preserve%22%3E%3Cg%3E%20%3Cpath%20style%3D%22fill%3A%2303C0AB%3B%22%20d%3D%22M40.5%2C10.333c-17.094%2C0-31%2C13.907-31%2C31c0%2C17.094%2C13.906%2C31%2C31%2C31s31-13.907%2C31-31%20C71.5%2C24.24%2C57.594%2C10.333%2C40.5%2C10.333z%20M34.5%2C32.51c0-1.65%2C0.924-2.325%2C2.353-1.5l15.662%2C9.113c1.429%2C0.825%2C1.368%2C2.175-0.061%2C3%20l-15.57%2C9.113c-1.429%2C0.825-2.384%2C0.15-2.384-1.5V32.51z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-size: 100% 100%;
}
.tm-audio-control-toggle-play.advance {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20version%3D%221.1%22%20id%3D%22play%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20width%3D%2280px%22%20height%3D%2280px%22%20viewBox%3D%220%200%2080%2080%22%20style%3D%22enable-background%3Anew%200%200%2080%2080%3B%22%20xml%3Aspace%3D%22preserve%22%3E%3Cg%3E%20%3Cpath%20style%3D%22fill%3A%2303C0AB%3B%22%20d%3D%22M40.5%2C10.333c-17.094%2C0-31%2C13.907-31%2C31c0%2C17.094%2C13.906%2C31%2C31%2C31s31-13.907%2C31-31%20C71.5%2C24.24%2C57.594%2C10.333%2C40.5%2C10.333z%20M40.5%2C68.429c-14.94%2C0-27.096-12.155-27.096-27.095S25.56%2C14.238%2C40.5%2C14.238%20s27.096%2C12.155%2C27.096%2C27.095S55.44%2C68.429%2C40.5%2C68.429z%22%2F%3E%3Cg%3E%3Cpath%20style%3D%22fill%3A%2303C0AB%3B%22%20d%3D%22M34.5%2C32.51c0-1.65%2C0.924-2.325%2C2.353-1.5l15.662%2C9.113c1.429%2C0.825%2C1.368%2C2.175-0.061%2C3%20l-15.57%2C9.113c-1.429%2C0.825-2.384%2C0.15-2.384-1.5V32.51z%22%2F%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-size: 100% 100%;
}
.tm-audio-control-toggle-pause {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20version%3D%221.1%22%20id%3D%22pause%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20width%3D%2280px%22%20height%3D%2280px%22%20viewBox%3D%220%200%2080%2080%22%20style%3D%22enable-background%3Anew%200%200%2080%2080%3B%22%20xml%3Aspace%3D%22preserve%22%3E%3Cg%3E%20%3Cpath%20style%3D%22fill%3A%2324B4A2%3B%22%20d%3D%22M40.5%2C9.167c-17.094%2C0-31%2C13.907-31%2C31c0%2C17.094%2C13.906%2C31%2C31%2C31s31-13.907%2C31-31%20C71.5%2C23.074%2C57.594%2C9.167%2C40.5%2C9.167z%20M36.434%2C50.059c0%2C1.475-1.196%2C2.67-2.67%2C2.67l0%2C0c-1.475%2C0-2.67-1.196-2.67-2.67V30.275%20c0-1.475%2C1.196-2.67%2C2.67-2.67l0%2C0c1.475%2C0%2C2.67%2C1.196%2C2.67%2C2.67V50.059z%20M49.907%2C50.059c0%2C1.475-1.196%2C2.67-2.67%2C2.67l0%2C0c-1.475%2C0-2.67-1.196-2.67-2.67V30.275%20c0-1.475%2C1.196-2.67%2C2.67-2.67l0%2C0c1.475%2C0%2C2.67%2C1.196%2C2.67%2C2.67V50.059z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-size: 100% 100%;
}
.tm-audio-control-toggle-pause.advance {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20version%3D%221.1%22%20id%3D%22pause%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20width%3D%2280px%22%20height%3D%2280px%22%20viewBox%3D%220%200%2080%2080%22%20style%3D%22enable-background%3Anew%200%200%2080%2080%3B%22%20xml%3Aspace%3D%22preserve%22%3E%3Cg%3E%20%3Cpath%20style%3D%22fill%3A%2324B4A2%3B%22%20d%3D%22M40.5%2C9.167c-17.094%2C0-31%2C13.907-31%2C31c0%2C17.094%2C13.906%2C31%2C31%2C31s31-13.907%2C31-31%20C71.5%2C23.074%2C57.594%2C9.167%2C40.5%2C9.167z%20M40.5%2C67.262c-14.94%2C0-27.096-12.155-27.096-27.095S25.56%2C13.072%2C40.5%2C13.072%20s27.096%2C12.155%2C27.096%2C27.095S55.44%2C67.262%2C40.5%2C67.262z%22%2F%3E%3Cg%3E%3Cpath%20style%3D%22fill%3A%2324B4A2%3B%22%20d%3D%22M36.434%2C50.059c0%2C1.475-1.196%2C2.67-2.67%2C2.67l0%2C0c-1.475%2C0-2.67-1.196-2.67-2.67V30.275%20c0-1.475%2C1.196-2.67%2C2.67-2.67l0%2C0c1.475%2C0%2C2.67%2C1.196%2C2.67%2C2.67V50.059z%22%2F%3E%3Cpath%20style%3D%22fill%3A%2324B4A2%3B%22%20d%3D%22M49.907%2C50.059c0%2C1.475-1.196%2C2.67-2.67%2C2.67l0%2C0c-1.475%2C0-2.67-1.196-2.67-2.67V30.275%20c0-1.475%2C1.196-2.67%2C2.67-2.67l0%2C0c1.475%2C0%2C2.67%2C1.196%2C2.67%2C2.67V50.059z%22%2F%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-size: 100% 100%;
}
