.tm-touchboard {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 8888;
  background: transparent;
}
.tm-touchboard-canvas {
  position: relative;
  width: 100%;
  height: 100%;
  background: transparent;
}
