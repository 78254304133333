.tm-picture-editor-brush-select {
  width: 100%;
  padding: 15px 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  max-width: 450px;
}
.tm-picture-editor-brush-select .tm-space-row {
  height: 20px;
}
