.tm-indicator-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -12px 0 0 -12px;
}
.tm-indicator-spinner-outer {
  border: 2px solid rgba(0, 229, 183, 0.9);
  opacity: 0.9;
  border-right-color: transparent;
  border-left-color: transparent;
  width: 24px;
  height: 24px;
  margin: 0 auto;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 10px rgba(0, 76, 61, 0.9);
          box-shadow: 0 0 10px rgba(0, 76, 61, 0.9);
  -webkit-animation: spin-pulse 1.2s linear infinite normal;
          animation: spin-pulse 1.2s linear infinite normal;
  -webkit-animation-delay: 0;
          animation-delay: 0;
}
.tm-indicator-spinner-inner {
  border: 2px solid rgba(0, 229, 183, 0.9);
  opacity: 0.9;
  border-left-color: transparent;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 6px rgba(0, 76, 61, 0.9);
          box-shadow: 0 0 6px rgba(0, 76, 61, 0.9);
  width: 10px;
  height: 10px;
  position: relative;
  top: -20px;
  margin: 0 auto;
  -webkit-animation: spin-right 1s linear infinite normal;
          animation: spin-right 1s linear infinite normal;
  -webkit-animation-delay: 0;
          animation-delay: 0;
}
@-webkit-keyframes spin-right {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    opacity: 0.1;
  }
  50% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    opacity: 0.8;
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    opacity: 0.1;
  }
}
@keyframes spin-right {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    opacity: 0.1;
  }
  50% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    opacity: 0.8;
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    opacity: 0.1;
  }
}
@-webkit-keyframes spin-pulse {
  from {
    -webkit-transform: rotate(160deg);
            transform: rotate(160deg);
    opacity: 0;
    -webkit-box-shadow: 0 0 1px rgba(0, 61, 76, 0.9);
            box-shadow: 0 0 1px rgba(0, 61, 76, 0.9);
  }
  50% {
    -webkit-transform: rotate(145deg);
            transform: rotate(145deg);
    opacity: 1;
  }
  to {
    -webkit-transform: rotate(-220deg);
            transform: rotate(-220deg);
    opacity: 0;
  }
}
@keyframes spin-pulse {
  from {
    -webkit-transform: rotate(160deg);
            transform: rotate(160deg);
    opacity: 0;
    -webkit-box-shadow: 0 0 1px rgba(0, 61, 76, 0.9);
            box-shadow: 0 0 1px rgba(0, 61, 76, 0.9);
  }
  50% {
    -webkit-transform: rotate(145deg);
            transform: rotate(145deg);
    opacity: 1;
  }
  to {
    -webkit-transform: rotate(-220deg);
            transform: rotate(-220deg);
    opacity: 0;
  }
}
