.tm-toast {
  position: fixed;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  z-index: 10000;
  top: 0;
  left: 0;
}
.tm-toast-fade-in {
  -webkit-animation: toastFadeIn 0.3s cubic-bezier(0.47, 0, 0.745, 0.715);
          animation: toastFadeIn 0.3s cubic-bezier(0.47, 0, 0.745, 0.715);
}
.tm-toast-fade-out {
  -webkit-animation: toastFadeOut 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
          animation: toastFadeOut 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
}
.tm-toast-content {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 12px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #333;
  opacity: 0.7;
  border-radius: 4px;
}
.tm-toast-content-custom {
  background-color: transparent;
}
.tm-toast-content-text {
  opacity: 1;
  font-size: 16px;
  color: white;
}
.tm-toast-content-loading {
  opacity: 1;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 4px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
          justify-content: space-around;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.tm-toast-content-loading span {
  margin-top: 16px;
  font-size: 15px;
  color: white;
}
.tm-toast-content-loading-indicator {
  width: 32px;
  height: 32px;
}
.tm-toast-content-loading-indicator-bar {
  background-color: white !important;
}
@-webkit-keyframes toastFadeIn {
  from {
    opacity: 0;
    -webkit-transform: scale(50%, 50%);
            transform: scale(50%, 50%);
  }
  to {
    opacity: 1;
    -webkit-transform: scale(100%, 100%);
            transform: scale(100%, 100%);
  }
}
@keyframes toastFadeIn {
  from {
    opacity: 0;
    -webkit-transform: scale(50%, 50%);
            transform: scale(50%, 50%);
  }
  to {
    opacity: 1;
    -webkit-transform: scale(100%, 100%);
            transform: scale(100%, 100%);
  }
}
@-webkit-keyframes toastFadeOut {
  from {
    opacity: 1;
    -webkit-transform: scale(100%, 100%);
            transform: scale(100%, 100%);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(50%, 50%);
            transform: scale(50%, 50%);
  }
}
@keyframes toastFadeOut {
  from {
    opacity: 1;
    -webkit-transform: scale(100%, 100%);
            transform: scale(100%, 100%);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(50%, 50%);
            transform: scale(50%, 50%);
  }
}
