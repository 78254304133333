.tm-picker {
  width: 100%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
          justify-content: space-around;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  overflow: hidden;
  border-radius: inherit;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.tm-picker-header {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  height: 44px;
  background-color: #f3f3f3;
  border-radius: inherit;
  padding-left: 20px;
  padding-right: 20px;
}
.tm-picker-header-text {
  font-size: 18px;
  color: #8d8d92;
}
.tm-picker-header-left {
  font-size: 15px;
  color: #8d8d92;
}
.tm-picker-header-right {
  font-size: 15px;
  color: #03c0ab;
}
.tm-picker-header-left-active,
.tm-picker-header-right-active {
  opacity: 0.75;
}
.tm-picker-body {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
          justify-content: space-around;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  width: 100%;
  margin: 8px;
}
.tm-picker-scroller {
  width: 100%;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}
.tm-picker-scroller-row-3 {
  height: 168px;
}
.tm-picker-scroller-row-5 {
  height: 280px;
}
.tm-picker-scroller-content {
  width: 100%;
}
.tm-picker-scroller-item {
  display: block;
  width: 100%;
  height: 56px;
  line-height: 56px;
  text-align: center;
  color: #333;
  font-size: 18px;
  font-weight: 500;
  font-family: 'Microsoft YaHei', Helvetica;
  -webkit-transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
       -o-transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
          transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
.tm-picker-scroller-item-deactive {
  color: #8d8d92;
  font-weight: 300;
}
.tm-picker-scroller-item-upper {
  -webkit-transform: rotate3d('20deg', '0deg', '0deg');
          transform: rotate3d('20deg', '0deg', '0deg');
  font-size: 16px;
}
.tm-picker-scroller-item-lower {
  -webkit-transform: rotate3d('-20deg', '0deg', '0deg');
          transform: rotate3d('-20deg', '0deg', '0deg');
  font-size: 16px;
}
.tm-picker-scroller-item-upper-1 {
  -webkit-transform: rotate3d('40deg', '0deg', '0deg');
          transform: rotate3d('40deg', '0deg', '0deg');
  font-size: 14px;
  opacity: 0.8;
}
.tm-picker-scroller-item-lower-1 {
  -webkit-transform: rotate3d('-40deg', '0deg', '0deg');
          transform: rotate3d('-40deg', '0deg', '0deg');
  font-size: 14px;
  opacity: 0.8;
}
.tm-picker .tm-picker-splitter-0 {
  position: absolute;
  left: 0;
  top: 56px;
  width: 100%;
  height: 0;
  border-top: 1px solid #ddd;
}
.tm-picker .tm-picker-splitter-1 {
  position: absolute;
  left: 0;
  top: 112px;
  width: 100%;
  height: 0;
  border-top: 1px solid #ddd;
}
.tm-picker .tm-picker-splitter-2 {
  position: absolute;
  left: 0;
  top: 168px;
  width: 100%;
  height: 0;
  border-top: 1px solid #ddd;
}
.tm-picker .tm-picker-splitter-3 {
  position: absolute;
  left: 0;
  top: 224px;
  width: 100%;
  height: 0;
  border-top: 1px solid #ddd;
}
