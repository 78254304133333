.tm-icon {
  fill: currentColor;
  background-size: cover;
  width: 22px;
  height: 22px;
}
.tm-icon-xxs {
  width: 15px;
  height: 15px;
}
.tm-icon-xs {
  width: 18px;
  height: 18px;
}
.tm-icon-sm {
  width: 21px;
  height: 21px;
}
.tm-icon-md {
  width: 22px;
  height: 22px;
}
.tm-icon-lg {
  width: 36px;
  height: 36px;
}
.tm-icon-xl {
  width: 48px;
  height: 48px;
}
.tm-icon-xxl {
  width: 56px;
  height: 56px;
}
.tm-icon-xxxl {
  width: 64px;
  height: 64px;
}
.tm-icon-loading {
  -webkit-animation: cirle-anim 1s linear infinite;
          animation: cirle-anim 1s linear infinite;
}
@-webkit-keyframes cirle-anim {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes cirle-anim {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
