.tm-share-modal {
  border-radius: 20px 20px 0 0;
}
.tm-share {
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  border-radius: 0 !important;
}
.tm-share-header {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #f3f3f3;
  font-size: 18px;
  color: #8d8d92;
  text-align: center;
  width: 100%;
  height: 36px;
  line-height: 36px;
}
.tm-share-content {
  width: 100%;
  padding: 0;
  margin: 0;
  border-collapse: separate;
  border-spacing: 12px;
  table-layout: fixed;
}
.tm-share-content tr {
  width: 100%;
  margin: 0;
  padding: 0;
}
.tm-share-content tr .tm-share-content-action {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.tm-share-content tr .tm-share-content-action-icon {
  width: 64px;
  height: 64px;
}
.tm-share-content tr .tm-share-content-action-text {
  height: 16px;
  line-height: 16px;
  font-size: 14px;
  color: #333;
  text-align: center;
  margin-top: 4px;
}
.tm-share-cancel {
  font-size: 16px;
  color: #03c0ab;
  height: 32px;
  line-height: 32px;
  text-align: center;
}
.tm-share-cancel-active {
  color: #02b09a;
}
