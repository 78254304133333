.tm-dropdown {
  display: inline-block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  background: transparent;
}
.tm-dropdown-content {
  display: block;
  position: absolute;
  margin: 0;
  padding: 0;
  left: 0;
  width: 100%;
  background: transparent;
  overflow: hidden;
  opacity: 1;
  z-index: 8888;
}
.tm-dropdown-content-deactive {
  display: none;
}
.tm-dropdown-content-slidein {
  -webkit-animation: TMDropDownSlideIn 0.2s cubic-bezier(0.55, 0.055, 0.675, 0.19);
          animation: TMDropDownSlideIn 0.2s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.tm-dropdown-content-slideout {
  -webkit-animation: TMDropDownSlideOut 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
          animation: TMDropDownSlideOut 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.tm-dropdown-content-ios {
  margin-top: 2px;
}
@-webkit-keyframes TMDropDownSlideIn {
  0% {
    height: 0;
    opacity: 0.75;
  }
  100% {
    height: 100%;
    opacity: 1;
  }
}
@keyframes TMDropDownSlideIn {
  0% {
    height: 0;
    opacity: 0.75;
  }
  100% {
    height: 100%;
    opacity: 1;
  }
}
@-webkit-keyframes TMDropDownSlideOut {
  0% {
    height: 100%;
    opacity: 1;
  }
  100% {
    height: 0;
    opacity: 0.75;
  }
}
@keyframes TMDropDownSlideOut {
  0% {
    height: 100%;
    opacity: 1;
  }
  100% {
    height: 0;
    opacity: 0.75;
  }
}
