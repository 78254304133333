.tm-context-menu {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: transparent;
  height: 100%;
  z-index: 9999;
  overflow: hidden;
}
.tm-context-menu-menu {
  position: absolute;
  color: #333;
  background-color: white;
  border-radius: 3px;
  -webkit-box-shadow: 0 0 2px rgba(0, 0, 0, 0.21);
          box-shadow: 0 0 2px rgba(0, 0, 0, 0.21);
  overflow: hidden;
}
.tm-context-menu-menu-item {
  padding: 0 8px;
}
.tm-context-menu-menu-item-container {
  position: relative;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  height: 39px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0 8px;
}
.tm-context-menu-menu-item:not(:first-child) .tm-context-menu-menu-item-container {
  border-top: 1PX solid #bbb;
}
@media (-webkit-min-device-pixel-ratio: 2), (-o-min-device-pixel-ratio: 2/1), (min-resolution: 2dppx) {
  html:not([data-scale]) .tm-context-menu-menu-item:not(:first-child) .tm-context-menu-menu-item-container {
    border-top: none;
  }
  html:not([data-scale]) .tm-context-menu-menu-item:not(:first-child) .tm-context-menu-menu-item-container::before {
    content: '';
    position: absolute;
    background-color: #bbb;
    display: block;
    z-index: 1;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    width: 100%;
    height: 1PX;
    -webkit-transform-origin: 50% 50%;
        -ms-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scaleY(0.5);
        -ms-transform: scaleY(0.5);
            transform: scaleY(0.5);
  }
}
@media (-webkit-min-device-pixel-ratio: 2) and (-webkit-min-device-pixel-ratio: 3), (-o-min-device-pixel-ratio: 2/1) and (-o-min-device-pixel-ratio: 3/1), (min-resolution: 2dppx) and (min-resolution: 3dppx) {
  html:not([data-scale]) .tm-context-menu-menu-item:not(:first-child) .tm-context-menu-menu-item-container::before {
    -webkit-transform: scaleY(0.33);
        -ms-transform: scaleY(0.33);
            transform: scaleY(0.33);
  }
}
.tm-context-menu-menu-item.tm-context-menu-menu-item-active .tm-context-menu-menu-item-container {
  border-top: 0;
}
.tm-context-menu-menu-item.tm-context-menu-menu-item-active .tm-context-menu-menu-item-container:before {
  display: none !important;
}
.tm-context-menu-menu-item.tm-context-menu-menu-item-active + .tm-context-menu-menu-item .tm-context-menu-menu-item-container {
  border-top: 0;
}
.tm-context-menu-menu-item.tm-context-menu-menu-item-active + .tm-context-menu-menu-item .tm-context-menu-menu-item-container:before {
  display: none !important;
}
.tm-context-menu-menu-item.tm-context-menu-menu-item-active {
  background-color: #bbb;
}
.tm-context-menu-menu-item.tm-context-menu-menu-item-active.tm-context-menu-menu-item-fix-active-arrow {
  position: relative;
}
.tm-context-menu-menu-item.tm-context-menu-menu-item-disabled {
  color: #bbb;
}
.tm-context-menu-menu-item.tm-context-menu-menu-item-disabled.tm-context-menu-menu-item-active {
  background-color: transparent;
}
.tm-context-menu-menu-item-icon {
  margin-right: 10px;
  width: 18px;
  height: 18px;
}
