.tm-lineedit {
  display: inline-block;
  border: 1px solid #aaa;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 4px;
  position: relative;
}
.tm-lineedit-content .tm-lineedit-editor-scrollable {
  overflow: hidden;
}
.tm-lineedit-content .tm-lineedit-editor {
  min-width: 64px;
  min-height: 18px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0;
  font-size: 16px;
  word-wrap: none;
  white-space: nowrap;
}
.tm-lineedit-content .tm-lineedit-editor-editable {
  -webkit-user-modify: read-write-plaintext-only;
  -webkit-user-select: auto;
     -moz-user-select: auto;
      -ms-user-select: auto;
          user-select: auto;
}
.tm-lineedit-content .tm-lineedit-editor[contenteditable="true"]:focus:hover {
  cursor: text;
}
.tm-lineedit-content .tm-lineedit-editor-password {
  -webkit-text-security: disc;
}
.tm-lineedit-content .tm-lineedit-editor[data-empty="true"]::before {
  position: absolute;
  content: attr(placeholder);
  color: #aaa;
  font-size: 16px;
}
.tm-lineedit-content-scrollable {
  position: absolute;
  left: 4px;
  right: 4px;
  bottom: 4px;
  top: 4px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
