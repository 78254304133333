.dn {
  display: none;
}
.df {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.dfc {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
}
.di {
  display: inline;
}
.db {
  display: block;
}
.dib {
  display: inline-block;
}
.ps {
  position: static;
}
.pr {
  position: relative;
}
.pa {
  position: absolute;
}
.pf {
  position: fixed;
}
.pac {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
.f1 {
  -webkit-flex: 1 1 auto;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
}
.f2 {
  -webkit-flex: 2 1 auto;
      -ms-flex: 2 1 auto;
          flex: 2 1 auto;
}
.f0 {
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
}
.jcc {
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.jcsa {
  -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
          justify-content: space-around;
}
.jcsb {
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.acc {
  -webkit-align-content: center;
      -ms-flex-line-pack: center;
          align-content: center;
}
.aic {
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.ass {
  -webkit-align-self: flex-start;
      -ms-flex-item-align: start;
          align-self: flex-start;
}
.ase {
  -webkit-align-self: flex-end;
      -ms-flex-item-align: end;
          align-self: flex-end;
}
.asc {
  -webkit-align-self: center;
      -ms-flex-item-align: center;
          align-self: center;
}
.fw {
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}
.fnw {
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
}
.n {
  font-weight: normal;
  font-style: normal;
}
.b {
  font-weight: bold;
}
.i {
  font-style: italic;
}
.tc {
  text-align: center;
}
.tr {
  text-align: right;
}
.tl {
  text-align: left;
}
.tj {
  text-align: justify;
}
.mt90 {
  margin-top: 1.2rem;
}
.pt90 {
  padding-top: 1.2rem;
}
#lib .header {
  -webkit-flex: none;
      -ms-flex: none;
          flex: none;
  height: 1.17333333rem;
  font-size: 0.48rem;
  color: #fff;
  background: #15a5a5;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
    License���ʹ�÷�Χ��Ȩ: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/* add */
body,
html {
  height: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
html {
  overflow: hidden;
  font-family: '微软雅黑', '宋体', 'sans-serif';
}
input,
textarea {
  font-family: '微软雅黑', '宋体', 'sans-serif';
}
input:focus:hover,
textarea:focus:hover {
  cursor: text;
}
#root {
  height: 100%;
}
img {
  border: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal !important;
}
select,
input {
  vertical-align: middle;
  -webkit-appearance: none;
  outline: none;
  border: none;
}
textarea {
  -webkit-appearance: none;
}
a {
  text-decoration: none;
  color: #000000;
}
a:hover {
  text-decoration: none !important;
}
html,
body {
  /*background: #f3f3f3;*/
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  height: 100%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: default;
}
.heade {
  position: fixed;
  top: 0;
}
.g-cover-text-edit {
  border: 1px solid #aaa;
}
