.tm-listview {
  -webkit-overflow-scrolling: touch;
}
.tm-listview-refresher {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-items: flex-end;
      -ms-flex-align: end;
          align-items: flex-end;
  width: 100%;
  height: 48px;
}
.tm-listview-refresher-animate {
  -webkit-transition: height 0.1s ease-out;
  -o-transition: height 0.1s ease-out;
  transition: height 0.1s ease-out;
}
.tm-listview-refresher-indicator {
  width: 18px;
  height: 18px;
  margin-bottom: 15px;
}
.tm-listview-loader {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-items: flex-end;
      -ms-flex-align: end;
          align-items: flex-end;
  width: 100%;
  height: 48px;
  padding: 4px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  overflow: hidden;
}
.tm-listview-loader-animate {
  -webkit-transition: height 0.1s ease-out;
  -o-transition: height 0.1s ease-out;
  transition: height 0.1s ease-out;
}
.tm-listview-loader-indicator {
  width: 18px;
  height: 18px;
  margin-bottom: 5px;
}
.tm-listview-loader-text {
  width: 100%;
  height: 40px;
  line-height: 40px;
  font-size: 15px;
  color: #999;
  text-align: center;
}
