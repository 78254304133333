.dn {
  display: none;
}
.df {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.dfc {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
}
.di {
  display: inline;
}
.db {
  display: block;
}
.dib {
  display: inline-block;
}
.ps {
  position: static;
}
.pr {
  position: relative;
}
.pa {
  position: absolute;
}
.pf {
  position: fixed;
}
.pac {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
.f1 {
  -webkit-flex: 1 1 auto;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
}
.f2 {
  -webkit-flex: 2 1 auto;
      -ms-flex: 2 1 auto;
          flex: 2 1 auto;
}
.f0 {
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
}
.jcc {
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.jcsa {
  -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
          justify-content: space-around;
}
.jcsb {
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.acc {
  -webkit-align-content: center;
      -ms-flex-line-pack: center;
          align-content: center;
}
.aic {
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.ass {
  -webkit-align-self: flex-start;
      -ms-flex-item-align: start;
          align-self: flex-start;
}
.ase {
  -webkit-align-self: flex-end;
      -ms-flex-item-align: end;
          align-self: flex-end;
}
.asc {
  -webkit-align-self: center;
      -ms-flex-item-align: center;
          align-self: center;
}
.fw {
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}
.fnw {
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
}
.n {
  font-weight: normal;
  font-style: normal;
}
.b {
  font-weight: bold;
}
.i {
  font-style: italic;
}
.tc {
  text-align: center;
}
.tr {
  text-align: right;
}
.tl {
  text-align: left;
}
.tj {
  text-align: justify;
}
.mt90 {
  margin-top: 1.2rem;
}
.pt90 {
  padding-top: 1.2rem;
}
.answer-detail {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
}
.answer-detail-topic-list {
  width: 100%;
  height: 1.6rem;
}
.answer-detail-topic-list .topic {
  height: 100%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 0.26666667rem;
}
.answer-detail-topic-list .topic-normal {
  width: 0.85333333rem;
  height: 0.85333333rem;
  margin-right: 0.13333333rem;
  position: relative;
}
.answer-detail-topic-list .topic-active {
  width: 1.30666667rem;
  height: 1.30666667rem;
  margin-right: 0.13333333rem;
  position: relative;
}
.answer-detail-topic-list .topic-bg {
  width: 100%;
  height: 100%;
}
.answer-detail-topic-list .topic-num {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.answer-detail-question {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
}
.answer-detail-question-type {
  color: #20a5a4;
  font-size: 0.45333333rem;
  padding-bottom: 0.26666667rem;
  padding-left: 0.13333333rem;
}
.answer-detail-stat {
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  padding-bottom: 0.4rem;
}
.answer-detail-stat-label {
  font-size: 0.45333333rem;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}
.answer-detail-data {
  position: relative;
}
.answer-detail-data-content {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
          justify-content: space-around;
}
.answer-detail-item {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  color: #fff;
  font-size: 0.32rem;
}
.answer-detail-item-data {
  font-size: 0.48rem;
  padding-bottom: 0.13333333rem;
}
.answer-detail-item-data > span {
  font-size: 0.8rem;
}
